import type { GeoJSONSource, MapLayerMouseEvent, MapMouseEvent } from "maplibre-gl";
import type { FlightState, GetAirportResponse, GetRouteResponse } from "~/services/opensky";
import type { Map } from "~/ui/";
import type { LayerInstanceOptions } from "./layer_instance";

import { distance, greatCircle } from "@turf/turf";
import { html, render } from "lighterhtml";
import { Popup } from "maplibre-gl";
import { DOM } from "maplibre-gl/src/util/dom";
import { plane_img } from "~/constants";
import { OpenSkyService } from "~/services";
import { normalizeMapMouseCoordinates, onceLoaded } from "~/utils";
import { LayerInstance } from "./layer_instance";

function lastContactFormat(value: number): string {
    if (!value) return "-";
    const minutes = Math.floor((Date.now() - value) / 1000 / 60);
    if (minutes <= 1) return "moments ago";
    if (minutes < 60) return `${minutes} min ago`;
    return "few hours ago";
}

function velocityFormat(value: number): string {
    if (!value) return "-";
    const kph = 3.6 * value;
    return `${+kph.toFixed(2)} km/h (${+value.toFixed(2)} m/s)`;
}

function altitudeFormat(value: number): string {
    if (!value) return "-";
    const ft = value * 3.280839895;
    return `${+value.toFixed(2)}m (${+ft.toFixed(2)} ft)`;
}

function countryFormat(value: string): string {
    if (!value) return "-";
    return new Intl.DisplayNames([ "en" ], { type: "region" }).of(value);
}

export type OpenSkyLayerOptions = {
    openSkyAuth: string
};

export class OpenSkyLayer extends LayerInstance<OpenSkyLayerOptions> {
    static readonly id = "nmapsgl_opensky";
    static readonly title = "Flights";

    private interval: NodeJS.Timeout;
    private abortControllers: AbortController[] = [];
    private loading = false;
    private symbolPopup: Popup;
    private persistedSymbolPopup: Popup;
    private popoverEl: HTMLElement;

    constructor(options?: OpenSkyLayerOptions & LayerInstanceOptions) {
        const filters = [
            { id: "opensky", filter: l => l.id === "open-sky" || l.id === "aircraft-trajectory" }
        ];
        // FIXME: super(filters, { initialEnabled: true, initialState: { opensky: true } });
        super(filters, options);
    }

    onAdd(map: Map): void {
        map.addSource("OpenSky", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        });
        map.addLayer({
            id: "open-sky",
            type: "symbol",
            source: "OpenSky",
            layout: {
                "icon-image": "airplane",
                "icon-size": .48,
                "icon-rotate": [ "get", "true_track" ],
                "icon-allow-overlap": true
            }
        });

        map.addSource("aircraft-trajectory", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        });
        map.addLayer({
            id: "aircraft-trajectory",
            source: "aircraft-trajectory",
            type: "line",
            layout: {
                "line-join": "round",
                "line-cap": "round"
            },
            paint: {
                "line-color": "#F7B500",
                "line-width": 3,
                "line-dasharray": [ 1, 2 ]
            }
        });
        super.onAdd(map);
        this.map.on("click", "open-sky", this.onMouseClick);
        this.map.on("mouseenter", "open-sky", this.onMouseEnter);
        this.map.on("mouseleave", "open-sky", this.onMouseLeave);
        this.map.on("click", this.onMapInteract);
        this.on("show", this.onShow);
        this.on("hide", this.onHide);
    }

    onRemove(): void {
        if (this.persistedSymbolPopup) {
            this.persistedSymbolPopup.remove();
            delete this.persistedSymbolPopup;
        }
        if (this.symbolPopup) {
            this.symbolPopup.remove();
            delete this.symbolPopup;
        }
        if (this.popoverEl) {
            this.popoverEl.remove();
        }
        this.map.off("click", this.onMouseClick);
        this.map.off("moveend", this.getPlanes);
        this.map.off("mouseenter", "open-sky", this.onMouseEnter);
        this.map.off("mouseleave", "open-sky", this.onMouseLeave);
        this.map.off("click", this.onMapInteract);
        this.abortControllers.forEach(e => e.abort());
        this.off("show", this.onShow);
        this.off("hide", this.onHide);
        super.onRemove();
    }

    private onShow = () => {
        onceLoaded(this.map, () => {
            const promise = !this.map.hasImage("airplane") ?
                this.map.loadImage(plane_img).then(res => this.map.addImage("airplane", res.data)) :
                Promise.resolve(true);

            promise.then(() => {
                this.getPlanes();
                this.map.on("moveend", this.getPlanes);
                this.interval = setInterval(() => !this.loading && this.getPlanes(), 10000);
            });
        });

    };

    private onHide = () => {
        this.resetData();
        if (this.persistedSymbolPopup) {
            this.persistedSymbolPopup.remove();
            delete this.persistedSymbolPopup;
        }
        if (this.symbolPopup) {
            this.symbolPopup.remove();
            delete this.symbolPopup;
        }
        if (this.popoverEl) {
            this.popoverEl.remove();
            delete this.popoverEl;
        }
        this.map.off("moveend", this.getPlanes);
        if (this.interval) {
            clearInterval(this.interval);
            delete this.interval;
        }
        this.abortControllers.forEach(e => e.abort());
    };

    private onMapInteract = (ev: MapMouseEvent) => {
        if (ev.defaultPrevented) return;
        if (this.popoverEl) {
            this.popoverEl.remove();
        }
        if (this.persistedSymbolPopup) {
            this.persistedSymbolPopup.remove();
            delete this.persistedSymbolPopup;
        }
        if (this.abortControllers[ 1 ]) {
            this.abortControllers[ 1 ].abort();
        }
    };

    private onMouseEnter = (ev: MapLayerMouseEvent) => {
        this.map.getCanvas().style.cursor = "pointer";

        const { properties } = ev.features[ 0 ];
        if (this.persistedSymbolPopup && this.persistedSymbolPopup._content.textContent === properties.callsign) {
            return;
        }

        if (this.symbolPopup) this.symbolPopup.remove();
        this.symbolPopup = new Popup({
            className: "nmapsgl-popup-tooltip",
            closeButton: false,
            closeOnClick: false,
            closeOnMove: false,
            offset: [ 0, -16 ]
        })
            .setLngLat(normalizeMapMouseCoordinates(ev))
            .setHTML(properties.callsign)
            .addTo(this.map);
    };

    private onMouseLeave = () => {
        this.map.getCanvas().style.cursor = "";
        if (this.symbolPopup) {
            this.symbolPopup.remove();
            delete this.symbolPopup;
        }
    };

    private onMouseClick = (ev: MapLayerMouseEvent) => {
        ev.preventDefault();
        const { properties } = ev.features[ 0 ];
        const { openSkyAuth } = this.options;

        if (this.persistedSymbolPopup && this.persistedSymbolPopup._content.textContent === properties.callsign) {
            if (this.symbolPopup) {
                this.symbolPopup.remove();
                delete this.symbolPopup;
            }
        } else {
            if (this.persistedSymbolPopup) {
                this.persistedSymbolPopup.remove();
                delete this.persistedSymbolPopup;
            }
            this.persistedSymbolPopup = this.symbolPopup;
            delete this.symbolPopup;
        }

        if (this.popoverEl) {
            this.popoverEl.remove();
        }
        this.popoverEl = DOM.create(
            "div", "nmapsgl-popover nmapsgl-popover-persistent nmapsgl-flight-popover", this.map.getPopoverContainer()
        );
        this.resetData();

        const props: PopoverProps = {
            isLoading: true,
            state: properties as OpenSkyService.FlightState
        };
        render(this.popoverEl, Popover(props));

        OpenSkyService.getRoute({ callsign: properties.callsign }, openSkyAuth, this.abortControllers[ 1 ])
            .then(data => props.route = data)
            .then(() => Promise.all([
                OpenSkyService.getAirport({ icao: props.route.route[ 0 ] }, openSkyAuth, this.abortControllers[ 1 ]),
                OpenSkyService.getAirport({ icao: props.route.route[ 1 ] }, openSkyAuth, this.abortControllers[ 1 ]),
            ]))
            .then(airports => props.airports = airports)
            .then(() => this.renderTrajectory(undefined, props.airports))
            .catch(_ => this.renderTrajectory(props.state))
            .finally(() => {
                props.isLoading = false;
                render(this.popoverEl, Popover(props));
            });
    };

    private resetData = () => {
        if (this.abortControllers[ 1 ]) {
            this.abortControllers[ 1 ].abort();
        }
        this.abortControllers[ 1 ] = new AbortController();

        const source = this.map.getSource("aircraft-trajectory") as GeoJSONSource;
        if (source) {
            source.setData({
                type: "FeatureCollection",
                features: []
            });
        }
    };

    private getPlanes = () => {
        if (document.visibilityState === "hidden") return;

        const source = this.map.getSource("OpenSky") as GeoJSONSource;
        if (!source) return;

        if (this.abortControllers[ 0 ]) {
            this.abortControllers[ 0 ].abort();
        }
        this.abortControllers[ 0 ] = new AbortController();

        this.loading = true;
        const bounds = this.map.getBounds();

        OpenSkyService.getAllStates({
            lamin: bounds.getSouthWest().lat,
            lomin: bounds.getSouthWest().lng,
            lamax: bounds.getNorthEast().lat,
            lomax: bounds.getNorthEast().lng
        }, this.options.openSkyAuth, this.abortControllers[ 0 ])
            .then(data => data.states.filter(e => !!e.callsign))
            .then((data) => {
                if (this.persistedSymbolPopup) {
                    const flight = data.find(e => e.callsign == this.persistedSymbolPopup._content.innerText);
                    if (flight) {
                        this.persistedSymbolPopup.setLngLat([ flight.longitude, flight.latitude ]);
                    } else {
                        this.persistedSymbolPopup.remove();
                        delete this.persistedSymbolPopup;
                    }
                }
                return data;
            })
            .then((states) => {
                source.setData({
                    type: "FeatureCollection",
                    features: states.map((e) => {
                        return {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [ e.longitude, e.latitude ]
                            },
                            properties: { ...e }
                        };
                    })
                });
            })
            .catch(() => { })
            .finally(() => this.loading = false); // TODO: error handling
    };

    private renderTrajectory = (state?: FlightState, airports?: [ GetAirportResponse, GetAirportResponse ]) => {
        const { openSkyAuth } = this.options;
        const source = this.map.getSource("aircraft-trajectory") as GeoJSONSource;
        if (airports) {
            source.setData({
                type: "FeatureCollection",
                features: [ greatCircle(
                    [ airports[ 0 ].position.longitude, airports[ 0 ].position.latitude ],
                    [ airports[ 1 ].position.longitude, airports[ 1 ].position.latitude ]
                ) ]
            });
        } else {
            OpenSkyService.getTrajectory({ icao24: state.icao24 }, openSkyAuth, this.abortControllers[ 1 ])
                .then(({ path }) => {
                    source.setData({
                        type: "FeatureCollection",
                        features: [ {
                            type: "Feature",
                            properties: {},
                            geometry: {
                                type: "LineString",
                                coordinates: path.map(e => [ e.longitude, e.latitude ])
                            }
                        } ]
                    });
                })
                .catch(() => { });
        }
    };
}

type PopoverProps = {
    isLoading: boolean,
    state?: FlightState,
    route?: GetRouteResponse,
    airports?: [ GetAirportResponse, GetAirportResponse ]
};
const Popover = ({ isLoading, state, route, airports }: PopoverProps) => {
    if (isLoading) return html`<div class="nmapsgl-spinner"></div>`;

    const Route = () => {
        if (route && airports) {
            const total = distance(
                [ airports[ 0 ].position.longitude, airports[ 0 ].position.latitude ],
                [ airports[ 1 ].position.longitude, airports[ 1 ].position.latitude ],
                { units: "kilometers" }
            );
            const travelled = distance(
                [ airports[ 0 ].position.longitude, airports[ 0 ].position.latitude ],
                [ state.longitude, state.latitude ],
                { units: "kilometers" }
            );
            const percent = Math.floor((travelled / total) * 100);
            return html`
                <div class="route">
                    <div>
                        <span class="origin-country">${route.route[ 0 ]}</span>
                        <div class="progress-bar">
                            <div class="progress" style="width: ${percent}%"></div>
                        </div>
                        <span class="origin-country">${route.route[ 1 ]}</span>
                    </div>
                    <div>
                        <span class="label">${countryFormat(airports[ 0 ].country)}</span>
                        <span class="label">${countryFormat(airports[ 1 ].country)}</span>
                    </div>
                </div>
            `;
        } else return html`
            <div>
                <div class="label">origin flight</div>
                <span class="origin-country">${state.origin_country}</span>
            </div>
        `;
    };

    return html`
        <div class="popover-content">
            <div class="top-container">
                <div>
                    <div class="callsign">
                        <i class="ic-plane" style="transform: rotate(${state.true_track}deg)"></i>
                        <span>${state.callsign}</span>
                    </div>
                    <div class="last-contact">
                        <i class="ic-refresh"></i>
                        <span>${lastContactFormat(state.last_contact)}</span>
                    </div>
                </div>
                ${Route()}
            </div>
            <div class="bottom-container">
                <div>
                    <div class="label">Speed</div>
                    <span class="value">${velocityFormat(state.velocity)}</span>
                </div>
                <div>
                    <div class="label">Altitude</div>
                    <span class="value">${altitudeFormat(state.geo_altitude)}</span>
                </div>
                <div>
                    <div class="label">Direction</div>
                    <span class="value">${state.true_track}º</span>
                </div>
            </div>
        </div>
    `;
};
