import type { IControl, Map } from "maplibre-gl";
import type { HTMLTooltipElement } from "~/utils";

import { DOM } from "maplibre-gl/src/util/dom";
import { extend } from "maplibre-gl/src/util/util";
import { addTooltip } from "~/utils";

type RotateOptions = {
    pitch?: number,
    minpitchzoom?: number
};

const defaultOptions: RotateOptions = {
    pitch: 50
};

/**
 * A `RotateControl` control provides a button to tilt up the map according to pitch and bearing values.
 *
 * @implements {IControl}
 * @param {Object} [options]
 * @param {number} [options.pitch=70] Sets the map's pitch (tilt). The pitch to set, measured in degrees away from the plane of the screen (0-60).
 * @param {number} [options.minpitchzoom=null] Minimum zoom level for 3D mode. Default is null, i.e., stays at same zoom.
 *
 * @example
 * map.addControl(new nmapsgl.RotateControl({
 *     pitch: 45,
 * }), 'bottom-right')
 */
export class RotateControl implements IControl {

    _map: Map;
    options: RotateOptions;
    _container: HTMLElement;
    _rotateButton: HTMLButtonElement;
    _slider: HTMLDivElement;
    _tooltip: HTMLTooltipElement;

    constructor(options?: RotateOptions) {
        this.options = extend({}, defaultOptions, options);
    }

    onAdd(map: Map): HTMLElement {
        this._map = map;

        this._container = DOM.create("div", "maplibregl-ctrl maplibregl-ctrl-group nmapsgl-ctrl-rotate-control");
        this._rotateButton = DOM.create("button", "maplibregl-ctrl-icon nmapsgl-ctrl-rotate-3d", this._container);
        this._rotateButton.setAttribute("aria-label", "Rotate Control");
        this._rotateButton.type = "button";
        this._rotateButton.addEventListener("click", this.rotate);
        this._tooltip = addTooltip(this._rotateButton, "3D Visualization");
        return this._container;
    }

    onRemove(): void {
        DOM.remove(this._container);
        delete this._map;
    }

    rotate = () => {
        if (this._rotateButton.classList.contains("nmapsgl-ctrl-rotate-3d")) {
            const options: any = {
                pitch: this.options.pitch
            };
            if (this.options.minpitchzoom && this._map.getZoom() > this.options.minpitchzoom) {
                options.zoom = this.options.minpitchzoom;
            }
            this._map.easeTo(options);
            this._rotateButton.className = "maplibregl-ctrl-icon nmapsgl-ctrl-rotate-2d";
            this._tooltip.setContent("2D Visualization");

            this._slider = DOM.create("div", "nmapsgl-ctrl-rotate-slider", this._container);
            const slider = DOM.create("input", "nmapsgl-ctrl-rotate-slider-input", this._slider);
            slider.type = "range";
            slider.min = "0";
            slider.max = "85";
            slider.value = this.options.pitch.toString();
            slider.oninput = (e) => {
                const pitch = parseInt((e.target as HTMLInputElement).value);
                this._map.easeTo({ pitch });
            };
            addTooltip(this._slider, "Slide to adjust");
        } else {
            this._map.easeTo({ pitch: 0 });
            this._rotateButton.className = "maplibregl-ctrl-icon nmapsgl-ctrl-rotate-3d";
            this._tooltip.setContent("3D Visualization");
            if (this._slider) DOM.remove(this._slider);
        }
    };

}
