import type { MapOptions as _MapOptions, StyleOptions, StyleSpecification, StyleSwapOptions } from "maplibre-gl";
import type { LayerInstance } from "./layers/layer_instance";

import { Map as _Map } from "maplibre-gl";
import { DOM } from "maplibre-gl/src/util/dom";
import { getDefaultStyle } from "~/constants";
import { KeyboardHandler } from "~/ui/handler";
import * as Layers from "~/ui/layers";
import { onceLoaded } from "~/utils";
import { RightClickHandler } from "./handler/right_click";

type MapOptions = _MapOptions & { layerOptions?: object, rightClick?: boolean, style?: StyleSpecification | string | undefined };

export class Map extends _Map {

    private popoverContainer: HTMLElement;
    private persistentLayers = new Set<string>();
    private layerInstances: Record<string, LayerInstance> = {};
    private layerOptions: Record<string, object> = {};

    constructor(options: MapOptions) {
        if (options.rightClick == undefined) options.rightClick = true;
        super(options);

        // @ts-expect-error Override error
        const keyboardHandler = new KeyboardHandler(this);
        keyboardHandler.enable();
        const keyboardHandlerIdx = this.handlers._handlers.findIndex(e => e.handlerName === "keyboard");
        this.handlers._handlers[ keyboardHandlerIdx ].handler = keyboardHandler;
        this.handlers._handlersById[ "keyboard" ] = keyboardHandler;
        // @ts-expect-error Override error
        this.keyboard = keyboardHandler;

        if (options.rightClick) {
            const rightClick = new RightClickHandler(this);
            this.on("click", (e) => { rightClick._click(e); });
            this.on("contextmenu", (e) => { rightClick.createOptions(e); });
        }

        if (!options.style) {
            this.setStyle(getDefaultStyle("STREET_STYLE").styleUrl, { localIdeographFontFamily: this._localIdeographFontFamily });
        }

        this.popoverContainer = document.createElement("div");
        this.popoverContainer.className = "nmapsgl-popover-container";

        const popoverBottomContainer = document.createElement("div");
        popoverBottomContainer.id = "nmapsgl-popover-bottom-container";

        this.popoverContainer.append(popoverBottomContainer);
        this._container.appendChild(this.popoverContainer);

        this.on("click", this.closeAllPopovers);
        this.on("drag", this.closeAllPopovers);

        if (options.layerOptions) {
            Object.entries(options.layerOptions).forEach(([ id, options ]) => this.layerOptions[ id ] = options);
        }
        Object.getOwnPropertyNames(Layers).filter(k => k.indexOf("__") != 0).forEach(k => this.addLayerInstance(Layers[ k ]));
    }

    closeAllPopovers = () => {
        const popovers = this.popoverContainer.querySelectorAll(".nmapsgl-popover:not(.nmapsgl-popover-persistent)");
        Array.from(popovers).forEach(e => e.remove());
    };

    _setupContainer(): void {
        super._setupContainer();
        [ "top-center", "left-center", "right-center", "bottom-center" ].forEach((positionName) => {
            this._controlPositions[ positionName ] = DOM.create("div", `maplibregl-ctrl-${positionName} `, this._controlContainer);
        });
    }

    getPopoverContainer(position?: "bottom"): HTMLElement {
        if (position == "bottom") return this.popoverContainer.querySelector("#nmapsgl-popover-bottom-container");
        return this.popoverContainer;
    }

    getLayerOptions(id: string) {
        return this.layerOptions[ id ];
    }

    persistLayers(ids: string[]) {
        ids.forEach(id => this.persistentLayers.add(id));
    }

    removePersistentLayers(ids: string[]) {
        ids.forEach(id => this.persistentLayers.delete(id));
    }

    addLayerInstance(InstanceClass): LayerInstance {
        if (!this.hasLayerInstance(InstanceClass.id)) {
            this.layerInstances[ InstanceClass.id ] = new InstanceClass(this.layerOptions[ InstanceClass.id ]);
            onceLoaded(this, () => this.layerInstances[ InstanceClass.id ].onAdd(this));
        }
        return this.getLayerInstance(InstanceClass.id);
        // TODO: call onRemove()
    }

    getLayerInstance(id: string) {
        return this.layerInstances[ id ];
    }

    hasLayerInstance(id: string) {
        return !!this.layerInstances[ id ];
    }

    setStyle(style: StyleSpecification | string | null, options?: StyleSwapOptions & StyleOptions & { persistLayers?: boolean }): this {
        const _options = { ...options };
        if (_options.persistLayers) {
            _options.transformStyle = (previous, next) => {
                const layers = previous.layers.filter(l => this.persistentLayers.has(l.id));
                const sources = {};
                layers.forEach((l) => {
                    if ("source" in l) sources[ l.source ] = previous.sources[ l.source ];
                });
                return ({
                    ...next,
                    sources: {
                        ...next.sources,
                        ...sources
                    },
                    layers: [
                        ...next.layers.filter(elem => !this.persistentLayers.has(elem.id)),
                        ...layers
                    ]
                });
            };
            delete _options.persistLayers;
        }
        return super.setStyle(style, _options);
    }
}
