import type { ControlPosition, IControl } from "maplibre-gl";
import type { Map } from "~/ui";
import type { LayerInstance } from "~/ui/layers/layer_instance";

import { extend } from "maplibre-gl/src/util/util";
import { OpenSkyLayer, TrafficLayer } from "~/ui/layers";
import { POIsLayer } from "~/ui/layers/pois_layer";
import { StreetViewLayer } from "~/ui/layers/streetview_layer";
import { WeatherLayer } from "~/ui/layers/weather_layer";
import { addTooltip, controlButton, controlContainer, html, setPopoverPosition } from "~/utils";

export type LayersControlLayer = {
    id: string;
    title: string;
    multiple?: boolean;
    options?: object;
};

export type LayersControlOptions = {
    hiddenLayers?: string[];
};

const defaultOptions: LayersControlOptions = {
    hiddenLayers: []
};

export class LayersControl implements IControl {
    private map: Map;
    private container: HTMLElement;
    private options: LayersControlOptions;
    private layers: Record<string, LayersControlLayer> = {};
    private popover: HTMLElement;
    private form: HTMLFormElement;

    constructor(options: LayersControlOptions) {
        this.options = extend({}, defaultOptions, options);
    }

    onAdd(map: Map): HTMLElement {
        this.map = map;
        this.setupLayer(OpenSkyLayer);
        this.setupLayer(TrafficLayer);
        this.setupLayer(WeatherLayer, false);
        this.setupLayer(POIsLayer);
        this.setupLayer(StreetViewLayer);

        this.container = controlContainer();
        const button = this.container.appendChild(controlButton({
            title: "Layers",
            className: "nmapsgl-ctrl-layers",
            onClick: this.onCtrlClick,
        }));
        addTooltip(button);
        return this.container;
    }

    onRemove(): void {
        delete this.map;
    }

    getDefaultPosition?: () => ControlPosition = () => "top-right";

    private onCtrlClick = (event: Event) => {
        event.stopPropagation();
        this.renderPopover();
        setPopoverPosition(event.currentTarget as HTMLElement, this.popover);
    };

    private renderPopover() {
        const renderFilters = (layer: LayersControlLayer, instance: LayerInstance) => {
            if (instance.filters.length < 2) return "";
            return html`
                <div>
                    <fieldset>
                        ${instance.filters.map(filter => html`
                            <label class="nmapsgl-option nmapsgl-option-${layer.multiple ? "checkbox" : "radio"}">
                                <input type="${layer.multiple ? "checkbox" : "radio"}"
                                    name="${layer.id}" value="${filter.id}"
                                >
                                <span class="option-label">${filter.label}</span>
                            </label>
                        `)}
                    </fieldset>
                </div>
            `;
        };

        const toggleExpanded = (event: Event) => {
            event.stopPropagation();
            const elem = event.currentTarget as HTMLElement;
            elem.parentElement.classList.toggle("expanded");
        };

        const renderLayerGroup = (layer: LayersControlLayer) => {
            const instance = this.map.getLayerInstance(layer.id);
            return html`
                <div id="${layer.id}" class="layer-block ${instance.filters.length < 2 && "without-filters"}">
                    <div class="block-header" onclick="${toggleExpanded}">
                        <i class="ic_arrow"></i>
                        <span>${layer.title}</span>
                        <label class="switch">
                            <input type="checkbox" name="${layer.id}" value="enabled">
                            <span class="slider"></span>
                        </label>
                    </div>
                    ${renderFilters(layer, instance)}
                </div>
            `;
        };

        this.map.closeAllPopovers();
        this.popover = this.map.getPopoverContainer().appendChild(html`
            <div id="nmapsgl-ctrl-layers-popover" class="nmapsgl-popover">
                <div class="popover-header">
                    <h2>Layer style</h2>
                    <button class="close" onclick="${this.onCloseClick}">
                        <span class="icon"></span>
                    </button>
                </div>
                <div class="popover-content">
                    <form id="layer-style-form">
                        ${Object.values(this.layers).map(renderLayerGroup)}
                    </form>
                </div>
            </div>
        `);
        this.form = this.popover.querySelector("#layer-style-form");
        this.setFormValues();
        this.form.addEventListener("change", this.onFormChange);
        this.setFormInputsDisabled();
    }

    private onFormChange = (event: Event) => {
        const elem = event.target as HTMLInputElement;
        const layerId = elem.name;
        const layerInstance = this.map.getLayerInstance(layerId);
        let enabled = false;
        const newState = {};
        this.getFormInputs(layerId).forEach((input) => {
            if (input.value === "enabled") enabled = input.checked;
            else newState[ input.value ] = input.checked;
        });
        layerInstance.setState(enabled, newState);
        this.setFormInputsDisabled();
    };

    private setFormValues = () => {
        Object.keys(this.layers).forEach((id) => {
            const instance = this.map.getLayerInstance(id);
            this.getFormInputs(id).forEach((elem) => {
                if (elem.value === "enabled") elem.checked = instance.isEnabled();
                else elem.checked = instance.isFilterEnabled(elem.value);
            });
        });
        this.setFormInputsDisabled();
    };

    private setFormInputsDisabled() {
        Object.keys(this.layers).forEach((id) => {
            const elems = this.getFormInputs(id);
            const switchEl = elems.find(e => e.value === "enabled");
            elems.forEach((e) => {
                if (e.value === "enabled") return;
                e.disabled = !switchEl.checked;
            });
        });
    }

    private getFormInputs(layerId: string): HTMLInputElement[] {
        let elements = this.form.elements[ layerId ];
        if (elements instanceof RadioNodeList) elements = Array.from(elements);
        else elements = [ elements ];
        return elements;
    }

    private onCloseClick = () => {
        this.popover.remove();
        delete this.popover;
    };

    private onChange = () => {
        if (this.popover) this.setFormValues();
    };

    setupLayer(InstanceClass, multiple = true) {
        if (this.options.hiddenLayers.includes(InstanceClass.id)) return;

        const instance = this.map.getLayerInstance(InstanceClass.id);
        instance.on("change", this.onChange);
        this.layers[ InstanceClass.id ] = {
            id: InstanceClass.id,
            title: InstanceClass.title,
            multiple
        };
    }
}
