import type { IControl } from "maplibre-gl";
import type { Map } from "~/ui";

import { DOM } from "maplibre-gl/src/util/dom";
import { extend } from "maplibre-gl/src/util/util";
import { getDefaultStyles } from "~/constants";
import { addTooltip, controlButton, controlContainer, html, setPopoverPosition } from "~/utils";

export type MapStyle = {
    label: string,
    styleName?: string,
    styleUrl: string,
    imageUrl?: string
};

type StyleOptions = {
    styles?: MapStyle[],
    onChange?: (style: MapStyle) => void,
    compact?: boolean,
    styleNames: string[]
};

const defaultOptions: () => StyleOptions = () => {
    const styles = getDefaultStyles();
    return {
        styles: styles,
        styleNames: styles.map(s => s.styleName),
        compact: false
    };
};

function validateOptions(options: StyleOptions) {
    // if (!options.styles.every(s => !!s.label && !!s.styleUrl)) throw Error("Invalid styles schema.");
    return true;
}

/**
 * A `StyleControl` control provides a set of buttons to switch between map styles.
 *
 * @implements {IControl}
 * @param {Object} [options]
 * @param {Array<Object>} [options.styles] Variable that represents the styles on which it is possible to switch. It is an object array where each element represents a Style with:
 *
 *   * label - name by which the style is named and which will serve to identify it.
 *   * styleURL - URL address that contains the style specification.
 *
 * By default, this variable contains the following styles: `'Streets'`, `'Light'`, `'Dark'` and `'Satellite'`.
 * @param {string} [options.defaultStyle="Streets"] Defines the style to be applied by default when the map is initialized.
 * If the `StyleControl` is used, the option 'defaultStyle' will override the style specified in the Map class constructor.
 *
 * To avoid re-rendering the map style, initialize the map with the same style as defined in 'defaultStyle'.
 * @param {Array<string>} [options.mapStyleIds] Allows you to filter part of the map styles defined in the "styles" variable.
 * `mapStyleIds` must be specified in the form of an array of strings, where each string identifies the style by its label.
 *
 * By default, all styles are presented to the user.
 *
 * @example
 * map.addControl(new nmapsgl.StyleControl({
 *  "mapStyleIds": ['Streets', 'Dark', 'Light'],
 * }), 'top-left');
 *
 * @example
 * map.addControl(new nmapsgl.StyleControl({
 *  "defaultStyle": 'Streets',
 *  "styles": [
 *       {
 *          "label": 'Bright',
 *          "styleUrl": 'https://api.maptiler.com/maps/bright/style.json',
 *       },
 *       {
 *          "label": 'Streets',
 *          "styleUrl": 'https://api.maptiler.com/maps/streets/style.json',
 *       }
 *   ]
 * }), 'top-left');
 */
export class StyleControl implements IControl {
    options: StyleOptions;
    container: HTMLElement;
    private map: Map;
    private stylesMap: Record<string, { style: MapStyle, element?: HTMLButtonElement }> = {};

    constructor(options?: StyleOptions) {
        this.options = extend({}, defaultOptions(), options);
        validateOptions(this.options);
        this.options.styleNames = this.options.styleNames.filter(e => this.options.styles.find(s => s.styleName === e));
        if (this.options.styleNames.length === 0) {
            this.options.styleNames = this.options.styles.map(s => s.styleName);
        }
        this.options.styles.forEach((style) => {
            this.stylesMap[ style.styleName ] = { style };
        });
    }

    onAdd(map: Map): HTMLElement {
        this.map = map;
        this.container = controlContainer();

        const button = this.container.appendChild(controlButton({ title: "Map Styles", className: "nmapsgl-ctrl-style", onClick: this.onCtrlClick }));
        addTooltip(button);

        this.map.on("styledata", this.setActiveButton);

        return this.container;
    }

    onRemove(): void {
        this.map.off("styledata", this.setActiveButton);
        DOM.remove(this.container);
        delete this.map;
    }

    private onCtrlClick = (event: Event) => {
        const { compact } = this.options;
        const target = event.currentTarget as HTMLElement;
        this.map.closeAllPopovers();
        return compact ? this.compact(target) : this.expanded(target);
    };

    private compact = (target: HTMLElement) => {
        const { styleNames } = this.options;

        const renderStyleElem = (styleName) => {
            const style = this.stylesMap[ styleName ].style;
            const button = (html`
                <button class="select-btn" data-style_name="${styleName}" onclick="${this.onStyleBtnClick}">
                    ${style.label}
                </button>
            `) as HTMLButtonElement;
            this.stylesMap[ styleName ].element = button;
            return button;
        };

        const popover = this.map.getPopoverContainer().appendChild(html`
            <div id="nmapsgl-ctrl-style-popover" class="nmapsgl-popover nmapsgl-ctrl-style-popover-compact">
                <div class="popover-content">
                    <div class="map-styles-container">
                        ${styleNames.map(renderStyleElem)}
                    </div>
                    <button class="close" onclick="${this.onCloseClick}">
                        <span class="icon"></span>
                    </button>
                </div>
            </div>
        `);
        setPopoverPosition(target, popover);
        this.setActiveButton();
    };

    private expanded = (target: HTMLElement) => {
        const { styleNames } = this.options;

        const renderStyleElem = (styleName) => {
            const style = this.stylesMap[ styleName ].style;
            const button = (html`
                <button class="select-btn" data-style_name="${styleName}" onclick="${this.onStyleBtnClick}">
                    <img src="${style.imageUrl}" />
                    ${style.label}
                    <div class="circle"></div>
                </button>
            `) as HTMLButtonElement;
            this.stylesMap[ styleName ].element = button;
            return button;
        };

        const popover = this.map.getPopoverContainer().appendChild(html`
            <div id="nmapsgl-ctrl-style-popover" class="nmapsgl-popover nmapsgl-ctrl-style-popover-expanded">
                <div class="popover-content">
                    <div class="map-styles-container">
                        ${styleNames.map(renderStyleElem)}
                    </div>
                    <button class="close" onclick="${this.onCloseClick}">
                        <span class="icon"></span>
                    </button>
                </div>
            </div>
        `);
        setPopoverPosition(target, popover);
        this.setActiveButton();
    };

    private onCloseClick = () => {
        this.map.getPopoverContainer().querySelector("#nmapsgl-ctrl-style-popover").remove();
    };

    private onStyleBtnClick = (e: Event) => {
        e.preventDefault();
        const button = e.currentTarget as HTMLButtonElement;
        const styleName = button.dataset[ "style_name" ];
        this.map.setStyle(this.stylesMap[ styleName ].style.styleUrl, { persistLayers: true });
    };

    private setActiveButton = () => {
        const newStyle = this.map.getStyle().name;
        Object.entries(this.stylesMap).forEach(([ styleName, { element } ]) => {
            if (!element) return;
            element.classList.remove("selected");
            if (styleName === newStyle) {
                element.classList.add("selected");
            }
        });
    };
}
