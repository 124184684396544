import type { LineString } from "@turf/turf";
import type { SequenceComponent } from "mapillary-js";
import type { GeoJSONSource, GetResourceResponse, IControl, LayerSpecification, LngLatLike, Point, SourceSpecification } from "maplibre-gl";
import type { Map } from "~/index";
import type { Unit } from "~/types";

import { autoUpdate, computePosition, flip, offset } from "@floating-ui/dom";
import polyline from "@mapbox/polyline";
import flatpickr from "flatpickr";
import { html, render } from "lighterhtml";
import flatten from "lodash.flatten";
import isEmpty from "lodash.isempty";
import { Viewer } from "mapillary-js";
import { LngLat, LngLatBounds, Popup } from "maplibre-gl";
import { DOM } from "maplibre-gl/src/util/dom";
import { extend } from "maplibre-gl/src/util/util";
import Toastify from "toastify-js";
import { findNearBy, genericSearch, getDirections, makeNMapsRequest, reverseGeocode } from "~/api";
import { marker_img, waypoint_img, waypoint_marker_img } from "~/constants";
import { Marker, getApiUrl } from "~/index";
import { MapillaryService } from "~/services";
import { addTooltip, convertDistance, formatDistance, formatTime, generateUniqueId, getDistance, onceLoaded, waitForDOMElement } from "~/utils";
import { POIsLayer } from "../layers";

type Profile = "Car" | "Truck" | "Walking";
type RoutingOptions = "Tolls" | "Motorways" | "Ferries" | "Freeways" | "Uturns" | "Small Roads" | "Country Crossing" | "Unpaved" | "Tunnels";

export type directionsOptions = {
    limit?: number,
    placeholder: string,
    includeDirections?: boolean,
    includeActions?: boolean,
    units?: Unit,
    routingOptions?: RoutingOptions[],
    profileIds?: Array<Profile>,
    prependElement?: HTMLElement,
    actionsElement?: HTMLElement
    directionActionsElement?: HTMLElement
};

const defaultOptions: directionsOptions = {
    limit: 5,
    placeholder: "Search for",
    includeDirections: true,
    includeActions: true,
    units: "Metric",
    profileIds: [ "Car", "Truck", "Walking" ],
    routingOptions: [ "Tolls", "Motorways", "Ferries" ]
};

const poisSourceName = "pois_results_source";
const poisLayerName = "pois_results_layer";
const resultSourceName = "result_source";
const resultLayerName = "result_layer";
const waypointsSourceName = "waypoints_source";
const waypointsLayerName = "waypoints_layer";
const routingSourceName = "routing_source";
const routingLayerName = "routing_layer";
const manouverSourceName = "step-geometry-source";
const manouverLayerName = "step-geometry-layer";
const maxWaypointsSize = 10;

const profiles = {
    "Car": {
        "icon": "ic_car",
        "mode": "fastest",
        "transportation": "Car",
    },
    "Truck": {
        "icon": "ic_truck",
        "mode": "fastest",
        "transportation": "Truck"
    },
    "Walking": {
        "icon": "ic_walk",
        "mode": "pedestrian",
        "transportation": "Car"
    }
};

const avoidOptions = [
    { "name": "Motorways", "value": "motorways" },
    { "name": "Tolls", "value": "toll" },
    { "name": "Ferries", "value": "ferry" },
    { "name": "Freeways", "value": "freeways" },
    { "name": "Uturns", "value": "uturns" },
    { "name": "Small Roads", "value": "small_roads" },
    { "name": "Country Crossing", "value": "country_crossing" },
    { "name": "Unpaved", "value": "unpaved" },
    { "name": "Tunnels", "value": "tunnels" }
];

// Toast
const toastDefinitions = {
    duration: 5000,
    close: true,
    gravity: "bottom",
    position: "center",
    className: "nmapsgl-toast"
};

function setStreetViewPosition(reference: HTMLElement, floating: HTMLElement): () => void {
    const update = () => {
        computePosition(reference, floating, {
            placement: "bottom-start",
            middleware: [
                offset(10),
                flip({ fallbackAxisSideDirection: "end", fallbackPlacements: [ "right-end" ] }),
            ]
        })
            .then(({ x, y }) => {
                floating.style.setProperty("top", `${y}px`);
                floating.style.setProperty("left", `${x}px`);
            });
    };
    return autoUpdate(reference, floating, update);
}

export class DirectionControl implements IControl {
    _map: Map;
    options: directionsOptions;
    _container: HTMLElement;
    private _searchWrapper: HTMLElement;
    private _resultsWrapper: HTMLElement;
    private _categoriesWrapper: HTMLElement;
    private _historyWrapper: HTMLElement;
    private _modal: HTMLElement;
    private categories = [];
    private categoriesMap = {};
    private searchHistory = [];
    private searchResults = [];
    private controller: AbortController;
    private hasPoisLayer: boolean;
    private currentToast: Toastify;
    private currentResult;
    private currentRouting;
    private mapillary_token;
    private currentStreetView;
    private streetViewWindowEl: HTMLElement;
    private closeEl: HTMLElement;
    private viewer: Viewer;
    private activeProfile;
    private waypoints = [
        { "id": "waypoint-origin", "value": {} },
        { "id": "waypoint-destination", "value": {} }
    ];
    private routingResults = {};
    private _geojson: GeoJSON.FeatureCollection;
    private _layers: LayerSpecification[] = [];
    private _selectedAlternative;
    private _previousManouver;
    private _previousHTMLResult;
    private _maneuverMarker: Marker;
    private _activePopup: Popup;
    private _hoverPopup: Popup;
    private leave_type = "Leave now";
    private currentAvoids = {};
    private units;
    private currentDate: Date;
    private currentTime;
    private waypointNumber = 1;
    private disabledUserLocation = false;
    protected eventTarget = new EventTarget();

    constructor(options: directionsOptions) {
        this.options = extend({}, defaultOptions, options);
        this.units = this.options.units;
        this.checkUserLocation();
    }

    onAdd(map: Map): HTMLElement {
        this._map = map;

        // Check Mapillary Token
        const streetview_layer = this._map.getLayerOptions("nmapsgl_street_view");
        if (streetview_layer && streetview_layer[ "mapillaryAccessToken" ])
            this.mapillary_token = streetview_layer[ "mapillaryAccessToken" ];

        this.streetViewWindowEl = this._map.getContainer().appendChild(html.node`
            <div id="nmapsgl-streetview-directions-window" class="nmapsgl-streetview-minimize">
                <div class="nmapsgl-streetview-mappilary-container">
                    <div id="nmapsgl-streetview-loading-wrapper">
                        <div class="nmapsgl-streetview-image-loader nmapsgl-icon-loading"></div>
                    </div>
                    <div id="nmapsgl-streetviewer-directions-container"></div>
                </div>
                <div id="nmapsgl-streetview-directions-wrapper-close" onclick=${this.onCloseClick}></div>
            </div>
        `);

        const mapillaryContainer = document.getElementById("nmapsgl-streetviewer-directions-container");
        this.closeEl = document.getElementById("nmapsgl-streetview-directions-wrapper-close");

        this.viewer = new Viewer({
            accessToken: this.mapillary_token,
            container: mapillaryContainer,
        });

        // Get categories
        this.getCategories().then(this.checkQueryParams);

        // Container
        this._container = DOM.create("div", "maplibregl-ctrl nmapsgl-ctrl-directions-control");
        render(this._container, this.renderControl);

        // Modal
        this._modal = DOM.create("div", "nmapsgl-ctrl-directions-modal");
        this._map.getContainer().appendChild(html.node`${this._modal}`);

        // Add event handler
        this._map.on("click", () => {
            this.displayDropdown(false);
            this.displayDirectionsDropdown(null, false);
        });

        this._container.addEventListener("click", (e) => {
            const target = e.target as HTMLElement;
            const classes = [ "nmapsgl-ctrl-directions-search-input", "waypoint-input", "waypoint-clear", "location-wrapper" ];
            const containsClass = classes.some(cls => target.classList.contains(cls));

            const dropdownResults = window.document.getElementById("waypoints-search-results");
            if (!containsClass && dropdownResults && dropdownResults.classList.contains("show")) {
                this.handlerOutsideClick();
            }
        });

        // Initialize current avoids
        avoidOptions.forEach(ao => this.currentAvoids[ ao.value ] = true);

        onceLoaded(this._map, () => {
            this._map.loadImage(marker_img).then(res => this._map.addImage("custom-marker", res.data));
            this._map.loadImage(waypoint_img).then(res => this._map.addImage("waypoint-marker", res.data));
            this._map.loadImage(waypoint_marker_img).then(res => this._map.addImage("custom-waypoint-marker", res.data));
        });
        return this._container;
    }

    onRemove(): void {
        DOM.remove(this._container);
        delete this._map;
    }

    renderControl = () => {
        // Search
        this._searchWrapper = DOM.create("div", "nmapsgl-ctrl-directions-search-input-wrapper");
        render(this._searchWrapper, this.renderSearch());

        // Results
        this._resultsWrapper = DOM.create("div", "nmapsgl-ctrl-directions-search-results-wrapper");

        // Categories
        this._categoriesWrapper = DOM.create("div", "nmapsgl-ctrl-directions-categories-wrapper");
        const hasCategories = this.categories?.length;
        render(this._categoriesWrapper, this.renderCategories(!hasCategories));

        // History
        this._historyWrapper = DOM.create("div", "nmapsgl-ctrl-directions-history-wrapper");
        render(this._historyWrapper, this.renderHistory(true, false, false, this.showHistoryResult, false));
        this.getHistory();

        waitForDOMElement("#routing-btn", this._searchWrapper)
            .then(elem => addTooltip(elem, "Directions", "bottom"));

        return html`
            ${this._searchWrapper}
            ${this._resultsWrapper}
            ${this._categoriesWrapper}
            ${this._historyWrapper}
        `;
    };

    // Render functions

    renderSearch = () => {
        return html`
            ${this.options.prependElement}
            <input type="text" class="nmapsgl-ctrl-directions-search-input" id="search-input" autocomplete="off"
                    placeholder=${this.options.placeholder} aria-label=${this.options.placeholder} 
                    onclick=${() => this.displayDropdown(true)} onkeydown=${e => e.key === "Enter" && this.keydown()}
                    oninput=${e => this.updateInput(e.target.value)}
            />
            <div class="nmapsgl-ctrl-directions-search-input-clear" id="search-clear" onclick=${this.clear}></div>
            <div class="nmapsgl-ctrl-directions-search-input-loading" id="search-loading"></div>
            <div class="nmapsgl-ctrl-directions-search-input-search" onclick=${this.keydown} id="search-icon"></div>
            ${this.options.includeDirections ? html`<button id="routing-btn" class="nmapsgl-ctrl-directions-routing-btn" onclick=${this.renderDirections}></button>` : html``}
        `;
    };

    renderCategories = (isLoading: boolean) => {
        if (isLoading) {
            this._categoriesWrapper.classList.add("loading");
            return html`<div class="nmapsgl-spinner"> </div>`;
        }

        this._categoriesWrapper.classList.remove("loading");
        if (this.categories?.length > 0) {
            return html`
                <div class="categories-header">
                    <span class="title">Categories</span>
                    <span class="categories-all" onclick=${() => this.displayCategories(true)}>see all</span>
                </div>
                <div class="categories-cards-wrapper">
                    ${this.categories.slice(0, 3).map(c => html`
                        <div class="categories-card" onclick=${() => this.findNearByCategorie(c)}>
                            <div class="icon" style="background-image: url(${this.categoriesMap[ c.id ]?.icon || this.categoriesMap[ "fallback" ].icon})"></div>
                            <span>${c.name}</span>
                        </div>
                    `)}
                </div>
            `;
        }
        return html``;
    };

    renderHistory = (isLoading, showAllHistoryResults, onlySelection, callback, currentLocation) => {
        if (isLoading) return html`<div class="nmapsgl-spinner"></div>`;

        const historyRes = showAllHistoryResults ? this.searchHistory : this.searchHistory.slice(0, 5);
        const label = `show ${showAllHistoryResults ? "less" : "more"} history results`;

        return html`
            ${currentLocation ? html`
                <div class="location-wrapper ${this.disabledUserLocation && "disabled"}" onclick=${() => this.callbackUserLocation(callback)}>
                    <div class="current-location"></div>
                    <div>Your location</div>
                </div>
            ` : html``}
            ${historyRes.map(s => html`
                <div class="history-list-item" onclick=${() => callback(s.value)}>
                    <div class="nmapsgl-ctrl-directions-history-icon"></div>
                    <div class="nmapsgl-ctrl-directions-history-description">
                        <p class="title">${s.value.name}</p>
                        <p class="subtitle">${this.getFormatedAddress(s.value)}</p>
                    </div>
                    ${!onlySelection ? html`<div class="nmapsgl-ctrl-directions-history-delete" onclick=${e => this.deleteHistory(e, s.key)}></div>` : html``}
                </div>
            `)}
            ${this.searchHistory.length > 5 && !onlySelection ? html`
                <div class="show-history" onclick=${() => this.showHistory(!showAllHistoryResults)}>${label}</div>
            `: html``}
        `;
    };

    renderAllCategories = () => {
        return html`
            <div class="header">
                <div class="description">
                    <div class="back" onclick = ${() => this.displayCategories(false)}> </div>
                    <div class="title"> Categories </div>
                </div>
                <div class="close" onclick = ${() => this.displayCategories(false, true)}> </div>
            </div>
            <div class="categories-list">
                ${this.categories.map(c => html`
                    <div class="categories-item" onclick=${() => this.findNearByCategorie(c)}>
                        <div class="description">
                            <div class="icon" style="background-image: url(${this.categoriesMap[ c.id ]?.icon || this.categoriesMap[ "fallback" ].icon})"></div>
                            <span>${c.name}</span>
                        </div>
                        <span class="arrow"></span>
                    </div>
                `)}
            </div>`;
    };

    renderCategoriesResults = (categorie, error) => {
        let body;

        if (error) {
            body = html`
                <div class="message-wrapper">
                    <div class="message-title">There was an error reaching the server</div>
                    <div class="message-description">
                        Please try again
                    </div>
                </div>
            `;
        } else {
            if (this.searchResults.length == 0) {
                body = html`
                    <div class="message-wrapper">
                        <div class="message-title">We couldn’t find any results</div>
                        <div class="message-description">
                            At this time there's no results available for this area.
                        </div>
                    </div>
                `;
            } else {
                const input = (document.getElementById("search-input") as HTMLInputElement);
                body = html`
                <div class="categorie-result-list-wrapper">
                    ${this.searchResults.map((r) => {
                    const distance = r.distance > 1000 ? `${(r.distance / 1000).toFixed(0)} km` : `${r.distance} m`;
                    return html`
                            <div class="categorie-result-list-item" onclick=${() => this.showResult(input, r)} 
                                onmouseover=${() => this.onMouseOverCategorieResult(r)} 
                                onmouseleave=${() => this.onMouseLeaveCategorieResult()}>
                                <div class="nmapsgl-ctrl-directions-poi-icon"></div>
                                <div class="nmapsgl-ctrl-directions-search-description">
                                    <p class="title">${r.name}</p>
                                    <div class="sub-title">
                                        <span>${distance}</span>
                                        <div class="bullet"></div>
                                        <span>${this.getPOICategorie(r.poi_info.categories[ 0 ])}</span>
                                    </div>
                                    <div class="address">${r.formatted_address}</div>
                                </div>
                            </div>
                        `;
                })}
                </div>`;
            }
        }

        return html`
            <div class="header">
                <div class="description">
                    <div class="back" onclick=${() => { this.hideCategorieResult(false); }}> </div>
                    <div class="title"> ${categorie} </div>
                </div>
                <div class="close" onclick=${() => this.hideCategorieResult(true)}> </div>
            </div>
            ${body}
        `;
    };

    renderResults = (inputEl, searchResults, callback) => {
        const categorie = this.categories.find(c => c.name.toLowerCase().includes(inputEl.value.toLowerCase()));
        return html`
            ${categorie && searchResults && html`
                <div class="categories-item" onclick=${() => this.findNearByCategorie(categorie)}>
                    <div class="description">
                        <div class="icon" style="background-image: url(${this.categoriesMap[ categorie.id ]?.icon || this.categoriesMap[ "fallback" ].icon})"></div>
                        <span>${categorie.name}</span>
                    </div>
                    <span class="arrow"></span>
                </div>
            `}
            ${this.searchResults.map((r) => {
            const icon = this.isInHistory(r) && searchResults ? "history" : "poi";
            return html`
                    <div class="search-list-item" onclick=${() => callback(inputEl, r)}>
                        <div class="nmapsgl-ctrl-directions-${icon}-icon"></div>
                        <div class="nmapsgl-ctrl-directions-search-description">
                            <p class="title">${r.name}</p>
                            <p class="subtitle">${this.getFormatedAddress(r)}</p>
                        </div>
                    </div>
                `;
        })}`;
    };

    renderResultDetail = (resultWrapper: HTMLElement, fromPopup: boolean) => {
        const distance = this.currentResult.distance > 1000 ? `${(this.currentResult.distance / 1000).toFixed(0)} km` : `${this.currentResult.distance} m`;
        const address = this.getResultAddress(this.currentResult);
        const resultDetail = html`
            <div class="result-detail">
                <div class="header">
                    <div class="description">
                        <div class="back" onclick=${() => this.hideResult(false)}> </div>
                        <div class="title">${this.currentResult.name}</div>
                    </div>
                    <div class="close" onclick=${() => this.hideResult(true)}> </div>
                </div>
                <div class="body">
                    <div class="img" style="background-image: url(${this.getImage(this.currentResult)})"></div>
                    <div class="description-wrapper">
                        <p class="title">${this.currentResult.name}</p>
                        <div class="sub-title">
                            ${(!fromPopup || (fromPopup && this.currentResult.distance > 3)) ? html`
                                <span>${distance}</span>
                                ${address && html`<div class="bullet"> </div>`}
                            ` : html``}
                            ${address && html`<span>${address}</span>`}
                        </div>
                    </div>
                    ${this.options.includeActions ? html`
                        <div class="detail-actions">
                            ${this.options.includeDirections ? html`
                                <div class="action" onclick=${() => this.linkToDirections(this.currentResult)}>
                                    <button class="nmapsgl-ctrl-directions-routing-btn"></button>
                                    <span> Directions </span>
                                </div>
                            ` : ""}
                            <div class="action" onclick=${this.share}>
                                <div class="share"></div>
                                <span> Share </span>
                            </div>
                        </div>
                    ` : this.options.actionsElement || html``}
                    <div class="result-detail-information">
                        <div class="item-wrapper">
                            <div class="nmapsgl-ctrl-directions-poi-icon"></div>
                            <div class="address">
                                <div class="title">${this.currentResult.formatted_address}</div>
                                <div class="sub-title">${this.currentResult.location.lat} , ${this.currentResult.location.lng}</div>
                            </div>
                        </div>
                        ${this.currentResult?.poi_info?.url && html`
                        <div class="item-wrapper">
                            <div class="nmapsgl-ctrl-directions-url-icon"></div>
                            <div class="item-value" style="word-break: break-all">${this.currentResult.poi_info.url[ 0 ]}</div>
                            <a class="nmapsgl-ctrl-directions-link-icon nmapsgl-pointer" href=${this.currentResult.poi_info.url[ 0 ]} target="_blank" rel="noopener noreferrer"></a>
                        </div>`}
                        ${this.currentResult?.poi_info?.email && html`
                        <div class="item-wrapper">
                            <div class="nmapsgl-ctrl-directions-email-icon"></div>
                            <div class="item-value">${this.currentResult.poi_info.email[ 0 ]}</div>
                            <a class="nmapsgl-ctrl-directions-link-icon nmapsgl-pointer" href="mailto:${this.currentResult.poi_info.email[ 0 ]}"></a>
                        </div>`}
                        ${this.currentResult?.poi_info?.phone && html`
                        <div class="item-wrapper">
                            <div class="nmapsgl-ctrl-directions-phone-icon"></div>
                            <div class="item-value">${this.currentResult.poi_info.phone[ 0 ]}</div>
                        </div>`}
                        <div class="item-wrapper">
                            <div class="nmapsgl-ctrl-directions-plus-code-icon"></div>
                            <div class="item-value">${this.currentResult.plus_code}</div>
                            <div class="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onclick=${() => { this.copyToClipboard(this.currentResult.plus_code); }}> </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
        const el = render(resultWrapper, resultDetail);

        /* eslint-disable */
        const event = new Event('openDetail');
        /* eslint-enable */
        this.eventTarget.dispatchEvent(event);

        const floating = this._map.getPopoverContainer().appendChild(html.node`
            <div id="streetview-wrapper" style="display: none" onclick=${this.showStreetView}>
                <div class="icon"></div>
            </div>
        `);
        setTimeout(() => setStreetViewPosition(el, floating), 500);
    };

    renderShare = () => {
        // Create url
        const url = window.location.href;
        const hashIndex = url.indexOf("#");
        const baseUrl = hashIndex !== -1 ? url.slice(0, hashIndex) : url;
        const hash = hashIndex !== -1 ? url.slice(hashIndex) : "";
        const queryParams = `?lat=${this.currentResult.location.lat}&lng=${this.currentResult.location.lng}&type=${this.currentResult.type}`;
        const shareUrl = `${baseUrl}${queryParams}${hash}`;
        const embededUrl = `${baseUrl}${queryParams}&embebed=true${hash}`;

        return html`
            <div class="share-modal show">
                <div class="header">
                    <div class="title">Share</div>
                    <div class="close" onclick = ${() => this._modal.classList.remove("show")}> </div>
                </div>
                <div class="tabs">
                    <div class="tablinks" id="defaultOpen" onclick=${e => this.openTab(e, "link")}>Send a link</div>
                </div>
                <div class="content">
                    <div class="tabcontent" id="link">
                        <div class="wrapper">
                            <div class="url">${shareUrl}</div>
                            <div class="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onclick=${() => { this.copyToClipboard(shareUrl); }}> </div>
                        </div>
                    </div>
                    <div class="tabcontent" id="embed"> 
                        <div class="wrapper">
                            <div class="url">${`<iframe src="${embededUrl}">`}</div>
                            <div class="nmapsgl-ctrl-directions-copy-icon nmapsgl-pointer" onclick=${() => { this.copyToClipboard(`<iframe src="${embededUrl}">`); }}> </div>
                        </div>
                        <div class="map">
                            <iframe src=${embededUrl}>
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    renderDirections = () => {
        let profilesKeys = Object.keys(profiles) as Array<Profile>;
        const filteredProfiles = profilesKeys.filter(profile => this.options.profileIds.includes(profile));
        profilesKeys = filteredProfiles.length > 0 ? filteredProfiles : profilesKeys;
        this.activeProfile = profilesKeys.length > 0 && profilesKeys[ 0 ];

        const directionsWrapper = html`
            <div class="profiles-header">
                <div class="profile-elems-wrapper">
                    ${profilesKeys.map(p => html`
                        <div class="profile-elem icon ${profiles[ p ].icon} ${p == this.activeProfile ? "active" : ""}" 
                            id="profile-${p}" onclick=${() => this.updateProfile(p)}></div>
                    `)}
                </div>
                <div class="directions-close" onclick=${this.closeDirections}></div>
            </div>
            <div class="waypoints-wrapper">
                <div id="waypoints" class="waypoints">
                    ${this.waypoints.map((wp, e) => {
            const iconClass = e === this.waypoints.length - 1 ? "destination" : "starting";
            const placeholder = e === 0 ? "Choose starting point" : "Choose destination";
            return html`
                        <div class="waypoint" id="${wp.id}">
                            <div>
                                ${e !== 0 ? html`<div class="icon-dots"></div>` : html``}
                                <div class="icon ${iconClass}"></div>
                            </div>
                            <div class="input-wrapper">
                                <input class="waypoint-input" placeholder=${placeholder} onkeydown=${this.searchKeydown}
                                    oninput=${e => this.updateWaypointInput(e.target.parentElement, false)}
                                    onclick=${e => this.displayDirectionsDropdown(e, true)}
                                >
                                <div class="waypoint-clear" onclick=${e => this.clearWaypoint(e)}></div>
                                <div class="waypoint-loading"></div>
                                <div class="waypoint-search show"></div>
                            </div>
                            <div class="bin-wrapper" onclick=${e => this.removeWaypoint(e)}>
                                <div class="bin"></div>
                            </div>
                        </div>
                       `;
        })}
                </div>
                ${this.waypoints.filter(wp => !isEmpty(wp.value)).length <= 2 ? html`<div id="directions-reverse-button" class="switch" onclick=${this.reverseWaypoints}></div>` : html``}
            </div>
            <div id="waypoints-search-results" class="nmapsgl-ctrl-directions-search-results-wrapper"></div>
            <div id="directions-options" class="nmapsgl-ctrl-directions-options"></div>
            ${this.options.directionActionsElement}
            <div id="waypoints-directions-results" class="nmapsgl-ctrl-directions-results-wrapper">
                    <div id="nmapsgl-directions-loading-wrapper"><div class="nmapsgl-icon-loading"></div></div>
            </div>
        `;
        render(this._container, directionsWrapper);

        // Add hover tooltip
        const reverseElem = window.document.getElementById("directions-reverse-button");
        addTooltip(reverseElem, "Click to reorder", "bottom");
    };

    renderAlternatives = (data) => {
        return html`
            ${this.renderAlternative(data.result, true, "result")}
            ${data.alternatives.map((alt, idx) => this.renderAlternative(alt, false, `alternative-${idx}`))}
        `;
    };

    renderAlternative = (result, active, id) => {
        return html`
            <div id="waypoint-${id}" class="nmapsgl-ctrl-directions-alternative-wrapper ${active ? "active" : ""}" onclick=${() => this.changeAlternative(id)}>
                <div class="description-wrapper" >
                    <div class="title-wrapper" >
                        <span class="time"> ${formatTime(result.total_time)} </span>
                        <div class="bullet"> </div>
                        <span class="alternative-distance">${formatDistance(result.total_distance, this.units, false)}</span>
                        ${result.traffic?.delay ? html`
                            <div class="bullet"></div>
                            <div class="delay">${formatTime(result.traffic.delay)} delay</div>
                        ` : html``}
                    </div>
                    <div class="sub-title"> ${result.name} </div>
                    ${result.crosses ? html`
                        <div class="directions-warning-wrapper">
                            <div class="directions-warning-icon"></div>
                            <div class="directions-warning">${this.generateWarning(result.crosses)}</div>
                        </div>
                    ` : html``}
                    ${result.crosses?.countrycrossing ? html`
                        <div class="directions-info-wrapper">
                            <div class="directions-info-icon"></div>
                            <div class="directions-info">Crossing border</div>
                        </div>
                    ` : html``}
                </div>
                <div id="arrow-right-${id}" class="arrow-right" onclick=${e => this.openAlternativeDetail(e, result, id)}> </div>
            </div>
        `;
    };

    openAlternativeDetail = (e, result, id) => {
        e.stopPropagation();
        this._previousHTMLResult = html`${Array.from(this._container.children).map(childElem => html`${childElem}`)}`;
        this.renderAlternativeDetail(result, id);
    };

    renderAlternativeDetail = (result, id) => {
        const htmlEl = html`
        <div class="alternative-detail">
             <div class="header alternative-detail-header">
                <div class="description">
                    <div class="back" onclick=${() => this.backAlternative(this._previousHTMLResult)}></div>
                    <div class="title"> Route details </div>
                </div>
            </div>
            <div class="alternative-header">
                <div class="description-wrapper">
                    <div class="title-wrapper" >
                        <span class="time">${formatTime(result.total_time)}</span>
                        <div class="bullet"> </div>
                        <span class="distance"> ${formatDistance(result.total_distance, this.units, false)} </span>
                    </div>
                    <div class="sub-title"> ${result.name} </div>
                    ${result.crosses ? html`
                        <div class="directions-warning-wrapper">
                            <div class="directions-warning-icon"></div>
                            <div class="directions-warning">${this.generateWarning(result.crosses)}</div>
                        </div>
                    ` : html``}
                    ${result.crosses?.countrycrossing ? html`
                        <div class="directions-info-wrapper">
                            <div class="directions-info-icon"></div>
                            <div class="directions-info">Crossing border</div>
                        </div>
                    ` : html``}
                </div>
            </div>
            <div class="manouver-list">
                ${result.legs.flatMap((leg) => {
            return leg.steps.map((step, i) => {
                return html`
                            <div class="manouver-elem" id="manouver-${id}-${i}" onclick=${() => this.displayStep(`manouver-${id}-${i}`, step, i)}>
                                <div class="manouver-icon nmapsgl-routing-maneuver-${step.maneuver}">
                                </div>
                                <div class="manouver-description">
                                    <div class="distance">${formatDistance(step.distance, this.units, false)}</div>
                                    <div class="instruction">${step.instruction}</div>
                                </div>
                            </div>
                        `;
            });
        })
            }
            </div>
        </div>
        `;
        render(this._container, htmlEl);
    };

    addWaypointInput = () => {
        if (this.waypoints.length < maxWaypointsSize && !isEmpty(this.waypoints[ this.waypoints.length - 1 ].value)) {
            const waypointInput = html.node`
                <div id="waypoint-${this.waypointNumber}" class="waypoint add-waypoint">
                    <div><div class="plus-icon"></div></div>
                    <div class="input-wrapper">
                        <input class="waypoint-input" placeholder="Add destination" onkeydown=${this.searchKeydown}
                            oninput=${e => this.updateWaypointInput(e.target.parentElement, false)}
                            onclick=${e => this.displayDirectionsDropdownWaypoint(e)}
                        >
                        <div class="waypoint-clear" onclick=${e => this.clearWaypoint(e)}></div>
                        <div class="waypoint-loading"></div>
                        <div class="waypoint-search show"></div>
                    </div>
                    <div class="bin-wrapper" onclick=${e => this.removeWaypoint(e)}>
                        <div class="bin"></div>
                    </div>
                </div>
            `;

            const waypointWrapper = window.document.getElementById("waypoints");
            waypointWrapper.appendChild(waypointInput);

            // Update vars
            this.waypoints.push({ id: `waypoint-${this.waypointNumber}`, value: {} });
            this.waypointNumber += 1;
        }
    };

    mouseOverListener = (event) => {
        const waypoint = event.target.closest(".waypoint");
        if (waypoint) {
            const binWrapper = waypoint.querySelector(".bin-wrapper");
            if (binWrapper) {
                binWrapper.classList.add("show");
            }
        }
    };
    mouseOutListener = (event) => {
        const waypoint = event.target.closest(".waypoint");
        if (waypoint) {
            const binWrapper = waypoint.querySelector(".bin-wrapper");
            if (binWrapper) {
                binWrapper.classList.remove("show");
            }
        }
    };

    displayDirectionsDropdownWaypoint = (e) => {
        const inputWrapper = (e.target.parentElement.parentElement as HTMLElement);
        if (inputWrapper.classList.contains("add-waypoint")) {
            if (inputWrapper.children.length && this.waypoints[ this.waypoints.length - 1 ].id == inputWrapper.id) {
                const newElement = html.node`
                    <div>
                        <div class="icon-dots"></div>
                        <div class="icon destination"></div>
                    </div>
                `;
                inputWrapper.replaceChild(newElement, inputWrapper.children[ 0 ]);
                inputWrapper.classList.remove("add-waypoint");
                (inputWrapper.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose destination";
            }

            // Previous sibling
            const previousSibling = inputWrapper.previousElementSibling;
            if (previousSibling && previousSibling.children.length) {
                const previousSiblingIdx = this.waypoints.findIndex(wp => wp.id == previousSibling.id);
                (previousSibling.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = previousSiblingIdx == 0 ? "Choose starting point" : "Choose waypoint";
                previousSibling.children[ 0 ].lastElementChild.className = "icon starting";
            }

            const reverseBtn = window.document.getElementById("directions-reverse-button");
            reverseBtn.classList.add("hide");

            // Add show bin icon on hover
            const binWrappers = window.document.getElementsByClassName("waypoint");
            Array.from(binWrappers).forEach((waypointInput: any) => {
                waypointInput.addEventListener("mouseover", this.mouseOverListener);
                waypointInput.addEventListener("mouseout", this.mouseOutListener);
            });
        }

        this.displayDirectionsDropdown(e, true);
    };

    removeWaypoint = (e) => {
        const waypoint = e instanceof HTMLElement ? e : (e.target as HTMLElement).classList.contains("bin-wrapper") ? e.target.parentElement : e.target.parentElement.parentElement;
        if (waypoint) {
            const wpIdx = this.waypoints.findIndex(wp => wp.id == waypoint.id);

            // Check if is first waypoint
            if (wpIdx == 0) {
                const nextElementSibling = waypoint.nextElementSibling as HTMLElement;
                nextElementSibling.children[ 0 ].removeChild(nextElementSibling.children[ 0 ].children[ 0 ]);
                (nextElementSibling.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose starting point";
            }

            // Check if waypoint to remove is last one
            if (waypoint.children[ 0 ].lastElementChild?.classList.contains("destination")) {
                // Previous sibling
                const previousSibling = waypoint.previousElementSibling;
                previousSibling.children[ 0 ].lastElementChild.className = "icon destination";
                previousSibling.children[ 1 ].children[ 0 ].placeholder = "Choose destination";
            }

            // Remove from waypoints variable
            this.waypoints = this.waypoints.filter(wp => wp.id !== waypoint.id);

            //Hise search results if open
            const resultsEl = window.document.getElementById("waypoints-search-results");
            resultsEl.classList.remove("show");

            // Remove waypoint HTML element
            waypoint.remove();

            // Remove previous routing layer
            this.removeRoutingLayer();

            if (this._activePopup) {
                this._activePopup.remove();
                this._activePopup = null;
            }

            // Re-render waypoints layer
            this.addWaypointsLayer();

            // Check number of waypoints to display reverse button and remove bin
            const showReverse = this.waypoints.length == 2 || this.waypoints.length == 3;
            if (showReverse) {
                const reverseBtn = window.document.getElementById("directions-reverse-button");
                reverseBtn.classList.remove("hide");

                const binWrappers = window.document.getElementsByClassName("waypoint");
                Array.from(binWrappers).forEach((waypointInput: any) => {
                    waypointInput.removeEventListener("mouseover", this.mouseOverListener);
                    waypointInput.removeEventListener("mouseout", this.mouseOutListener);

                    // Remove as referências após a remoção
                    delete waypointInput._mouseOverListener;
                    delete waypointInput._mouseOutListener;
                });
            }
        }
    };

    backAlternative = (previousHTMLContent) => {
        // Render previous HTML
        render(this._container, previousHTMLContent);

        // Update selected alternative
        const alternativeElems = window.document.getElementsByClassName("nmapsgl-ctrl-directions-alternative-wrapper");
        Array.from(alternativeElems).forEach((pe) => {
            pe.classList.remove("active");
            pe.id === `waypoint-${this._selectedAlternative}` && pe.classList.add("active");
        });

        this.fitToWaypoints();

        // Remove maneuver marker if defined
        if (this._maneuverMarker) {
            this._maneuverMarker.remove();
            this._maneuverMarker = null;
        }
        this._previousManouver = -1;

        // Remove manouver layer and source if defined
        if (this._map.getSource(manouverSourceName)) {
            this._map.removeLayer(manouverLayerName);
            this._map.removeSource(manouverSourceName);
        }
    };

    displayStep = (id, step, i) => {
        // Remove current active
        const elem = window.document.getElementById(id);
        const elemList = window.document.getElementsByClassName("manouver-list")[ 0 ];
        Array.from(elemList.children).forEach(elem => elem.classList.remove("active"));

        // Remove manouver layer and source if defined
        if (this._map.getSource(manouverSourceName)) {
            this._map.removeLayer(manouverLayerName);
            this._map.removeSource(manouverSourceName);
        }

        if (this._previousManouver == i) {
            if (this._maneuverMarker) {
                this._maneuverMarker.remove();
                this._maneuverMarker = null;
            }

            this._previousManouver = -1;
            this.fitToWaypoints();
        } else {
            // Highlight step
            elem.classList.add("active");
            this._previousManouver = i;
            // Marker
            this._map.flyTo({
                center: [ step.location.lng, step.location.lat ],
                speed: 1.3,
                zoom: 16
            });
            if (!this._maneuverMarker) {
                // Create popup
                const popup = new Popup({ offset: 5, closeButton: false, closeOnClick: false, anchor: "right", className: "manouver-popup" });

                // Create marker
                const el = DOM.create("div", "nmapsgl-maneuver-marker");
                this._maneuverMarker = new Marker({ element: el })
                    .setLngLat([ step.location.lng, step.location.lat ])
                    .setPopup(popup)
                    .addTo(this._map);

                // Get streetview image to result
                if (this.mapillary_token) {
                    this.getMapillaryImage(step.location)
                        .then((res) => {
                            if (res) {
                                popup.setHTML(`
                                            <div class="manouver-step-wrapper">
                                                <div class="manouver-streetview-image" style="background-image:url(${res[ "thumb_256_url" ]})"></div>
                                                <div> ${step[ "instruction" ]} </div>
                                            </div>
                                        `);
                            } else {
                                popup.setHTML(`<div>${step[ "instruction" ]}</div>`);
                            }
                        })
                        .catch(() => {
                            console.error("Error fetching mapillary API.");
                        })
                        .finally(() => this._maneuverMarker.togglePopup());
                } else {
                    popup.setHTML(`<div>${step[ "instruction" ]}</div>`);
                    this._maneuverMarker.togglePopup();
                }
            } else {
                const markerPopup = this._maneuverMarker.getPopup();
                this._maneuverMarker.togglePopup();

                // Get streetview image to result
                if (this.mapillary_token) {
                    this.getMapillaryImage(step.location)
                        .then((res) => {
                            if (res) {
                                markerPopup.setHTML(`
                                    <div class="manouver-step-wrapper">
                                        <div class="manouver-streetview-image" style="background-image:url(${res[ "thumb_256_url" ]})"></div>
                                        <div> ${step[ "instruction" ]} </div>
                                    </div>
                                `);
                                this._maneuverMarker.togglePopup();
                            } else {
                                markerPopup.setHTML(`<div>${step[ "instruction" ]}</div>`);
                                this._maneuverMarker.togglePopup();
                            }
                        });
                } else {
                    markerPopup.setHTML(`<div>${step[ "instruction" ]}</div>`);
                    this._maneuverMarker.togglePopup();
                }

                this._maneuverMarker.setLngLat([ step.location.lng, step.location.lat ]);
            }
            // Geometry
            const features = [];
            const decoded = polyline.decode(step[ "geometry" ], 6).map((c) => {
                return c.reverse();
            });
            decoded.forEach((c) => {
                const previous = features[ features.length - 1 ];

                if (previous) {
                    previous.geometry.coordinates.push(c);
                } else {
                    const segment = {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "LineString",
                            "coordinates": []
                        }
                    };
                    // New segment starts with previous segment's last coordinate.
                    if (previous) segment.geometry.coordinates.push(previous.geometry.coordinates[ previous.geometry.coordinates.length - 1 ]);
                    segment.geometry.coordinates.push(c);
                    features.push(segment);
                }
            });
            this._map.addSource(manouverSourceName, {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": features
                }
            });
            const layer: LayerSpecification = {
                "id": manouverLayerName,
                "type": "line",
                "source": manouverSourceName,
                "paint": {
                    "line-color": "#0A64C7",
                    "line-width": 4
                },
                "layout": {
                    "visibility": "visible"
                }
            };
            this._map.addLayer(layer);
        }

    };

    searchKeydown = (e) => {
        if (e.key === "Enter") {
            const parentElem = e.target.parentElement as HTMLElement;
            if (parentElem) {
                parentElem.children[ 2 ].classList.add("show");
                parentElem.children[ 3 ].classList.remove("show");
                const resultsEl = window.document.getElementById("waypoints-search-results");
                this.search(e.target, resultsEl, false, this.addWaypoint)
                    .then(() => {
                        resultsEl.classList.add("show");
                        parentElem.children[ 2 ].classList.remove("show");
                    });
            }
        }
    };

    fitToWaypoints = () => {
        // Fit map to bounds
        const filteredWaypoints = this.waypoints.filter(wp => !isEmpty(wp.value));
        const bounds = filteredWaypoints.map(wp => wp.value[ "location" ]).reduce((bounds, coord) => {
            return bounds.extend(coord);
        }, new LngLatBounds(filteredWaypoints[ 0 ].value[ "location" ]));
        this._map.fitBounds(bounds, { padding: 100 });
    };

    linkToDirections = (result) => {
        this.renderDirections();

        // Clear results
        this.searchResults = [];
        if (this._map.getSource(resultSourceName)) {
            this._map.removeLayer(resultLayerName);
            this._map.removeSource(resultSourceName);
        }

        const wpElemDest = window.document.getElementById(this.waypoints[ 1 ].id).children[ 1 ];
        const inputElem = wpElemDest.children[ 0 ] as HTMLInputElement;
        this.addWaypoint(inputElem, result);

        // Hide streetview wrapper
        const streetviewEl = (document.getElementById("streetview-wrapper") as HTMLElement);
        if (streetviewEl) streetviewEl.style.display = "none";
    };

    renderOptionsWrapper = () => {
        const date = new Date();
        this.currentTime = this.currentTime || `${date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`;
        return html`
            <div class="directions-options">
                <div class="leave-wrapper">
                    ${[ "Leave now", "Depart at" ].map((leave, e) => { return html`<div class="leave-btn ${leave == this.leave_type && "active"}" onclick=${e => this.changeLeaveType(e)}>${leave}</div>`; })}
                </div>
                <div class="options-label" onclick=${this.renderOptions}>Options</div>
            </div>
            <div id="leave-picker" class="leave-picker ${this.leave_type !== "Leave now" && "show"}">
            <div class="leave-time-picker">
                <div class="time-input-wrapper">
                    <div class="leave-time-input-icon"></div>
                    <input type="text" class="leave-time-input" id="leave-time-input" value=${this.currentTime} readonly>
                </div>
                <div class="time-dropdown" id="time-dropdown">
                    <div class="dropdown-section">
                        <ul id="hours-list" class="time-picker-list"></ul>
                    </div>
                    <div class="dropdown-section">
                        <ul id="minutes-list" class="time-picker-list"></ul>
                    </div>
                </div>
            </div>
                <input type="text" id="leave-date-picker">
            </div>
        `;
    };

    renderOptions = () => {
        const filteredOptions = avoidOptions.filter(option => this.options.routingOptions.includes(option.name as RoutingOptions));
        const optionsKeys = filteredOptions.length > 0 ? filteredOptions : avoidOptions;

        const htmlEl = html`
            <div class="options-container-wrapper">
                <div class="options-container-wrapper-header">
                    <div class="options-container-wrapper-title">Options</div>
                    <div id="options-container-wrapper-close" onclick=${this.closeOptions}></div>
                </div>
                <div class="options-container-wrapper-body">
                    <div class="avoid-wrapper">
                        <div class="label">Avoid</div>
                        ${optionsKeys.map((avoid) => {
            return html`
            <div class="checkbox-wrapper">
                <input type="checkbox" id=${avoid.name} name=${avoid.name} checked=${!this.currentAvoids[ avoid.value ]} value=${avoid.value} onchange=${this.changeAvoids} />
                <label for=${avoid.name}>${avoid.name}</label>
            </div>
                        `;
        })}
                    </div>
                    <div class="units-wrapper">
                        <div class="label">Distance units</div>
                                ${[ "Metric", "Imperial" ].map((unit) => {
            return html`
                                    <div class="checkbox-wrapper">
                                        <input type="radio" id=${unit} name="units" value=${unit} checked=${this.units == unit} onchange=${this.changeUnits}/>
                                        <label for=${unit}>${unit}</label>
                                    </div>
                                    `;
        })}
                    </div>
                </div>
            </div>
        `;
        const container = window.document.getElementById("directions-options");
        render(container, htmlEl);
    };

    renderLeaveDate = () => {
        this.currentDate = this.currentDate || new Date();
        flatpickr("#leave-date-picker", {
            altInput: true,
            altFormat: "D, M j",
            dateFormat: "Y-m-d",
            defaultDate: this.currentDate,
            altInputClass: "leave-date-picker",
            onChange: this.updateLeaveDate
        });
    };

    closeOptions = () => {
        const container = window.document.getElementById("directions-options");
        render(container, this.renderOptionsWrapper);
        this.addTimeInputHandler();
        this.renderLeaveDate();
    };

    updateLeaveDate = (_, d) => {
        this.currentDate = new Date(d);
        this.addWaypointsLayer();
    };

    addTimeInputHandler = () => {
        const timeInput = document.getElementById("leave-time-input") as HTMLInputElement;
        const timeDropdown = document.getElementById("time-dropdown");
        const hoursList = document.getElementById("hours-list");
        const minutesList = document.getElementById("minutes-list");

        // Populate the hours and minutes lists
        for (let i = 0; i < 24; i++) {
            const hour = i < 10 ? `0${i}` : i;
            hoursList.insertAdjacentHTML("beforeend", `<li data-value="${hour}">${hour}</li>`);
        }

        for (let i = 0; i < 60; i++) {
            const minute = i < 10 ? `0${i}` : i;
            minutesList.insertAdjacentHTML("beforeend", `<li data-value="${minute}">${minute}</li>`);
        }

        const timeInputValue = timeInput.value.split(":");
        let selectedHour = timeInputValue[ 0 ];
        let selectedMinute = timeInputValue[ 1 ];

        const scrollToSelected = (list) => {
            const selectedValue = list === hoursList ? selectedHour : selectedMinute;
            const selectedItem = list.querySelector(`[data-value="${selectedValue}"]`);
            if (selectedItem) {
                selectedItem.scrollIntoView({ behavior: "auto", block: "center" });
            }
        };

        // Open dropdown on input click
        timeInput.addEventListener("click", () => {
            timeInput.classList.add("open");
            timeDropdown.classList.add("active");
            scrollToSelected(hoursList);
            scrollToSelected(minutesList);
        });

        const updateTime = () => {
            if (selectedHour !== null && selectedMinute !== null) {
                timeInput.value = `${selectedHour}:${selectedMinute}`;
            }
        };

        hoursList.addEventListener("click", (e) => {
            const target = e.target as HTMLElement;
            if (target.tagName === "LI") {
                selectedHour = target.getAttribute("data-value");
                updateTime();
                updateSelection("hours");
            }
        });

        minutesList.addEventListener("click", (e) => {
            const target = e.target as HTMLElement;
            if (target.tagName === "LI") {
                selectedMinute = target.getAttribute("data-value");
                updateTime();
                updateSelection("minutes");
            }
        });

        // Close dropdown when click outside dropdown
        document.addEventListener("click", (event) => {
            if (!(event.target as HTMLElement).closest(".leave-time-picker")) {
                timeInput.classList.remove("open");
                timeDropdown.classList.remove("active");

                if (timeInput.value != this.currentTime) {
                    this.currentTime = timeInput.value;
                    this.addWaypointsLayer();
                }
            }
        });

        const updateSelection = (type) => {
            const list = type === "hours" ? hoursList : minutesList;
            const selectedValue = type === "hours" ? selectedHour : selectedMinute;
            const items = list.querySelectorAll("li");
            items.forEach((item) => {
                if (item.getAttribute("data-value") === selectedValue) {
                    item.classList.add("time-selected");
                } else {
                    item.classList.remove("time-selected");
                }
            });
        };

        // Initialize selected time
        updateSelection("hours");
        updateSelection("minutes");
    };

    handlerOutsideClick = () => {
        // Hide dropdown
        this.displayDropdown(false);
        this.displayDirectionsDropdown(null, false);

        // Fix UI
        const indexWaypoints = this.waypoints.map((wp, i) => ({ ...wp, i }));
        const filledWaypoints = indexWaypoints.filter(wp => !isEmpty(wp.value));
        const emptyWaypoints = indexWaypoints.filter(wp => isEmpty(wp.value));

        // Hide directions options
        const directionsOptionsWrapper = window.document.getElementById("directions-options");
        directionsOptionsWrapper.classList.remove("show");

        // Hide directions results
        const waypointDirectionsWrapper = window.document.getElementById("waypoints-directions-results");
        waypointDirectionsWrapper.classList.remove("show");
        render(waypointDirectionsWrapper, html`<div id="nmapsgl-directions-loading-wrapper"><div class="nmapsgl-icon-loading"></div></div>`);

        // If all inputs are empty: initial state with empty inputs and reverse button visible
        if (filledWaypoints.length == 0) {
            const deleted = this.waypoints.splice(2);
            deleted.map((wp_del) => {
                const wp_elem = window.document.getElementById(wp_del.id);
                wp_elem.remove();
            });

            // Fix icons and placeholders
            const originElem = window.document.getElementById(this.waypoints[ 0 ].id);
            originElem.children[ 0 ].lastElementChild.className = "icon starting";
            (originElem.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose starting point";

            const destinationElem = window.document.getElementById(this.waypoints[ 1 ].id);
            destinationElem.children[ 0 ].lastElementChild.className = "icon destination";
            (destinationElem.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose destination";
        }

        // If only one input is filled: initial state with one input filled and reverse button visible
        if (filledWaypoints.length == 1) {
            // If the first input is filled: initial state with origin filled
            if (filledWaypoints[ 0 ].i == 0) {
                const deleted = this.waypoints.splice(2);
                deleted.forEach((wp_del) => {
                    const wp_elem = window.document.getElementById(wp_del.id);
                    wp_elem.remove();
                });
            }
            // If other input is filled: initial state with destination filled
            else {
                this.waypoints = [ this.waypoints[ 0 ], this.waypoints[ filledWaypoints[ 0 ].i ] ];
                emptyWaypoints.slice(1).forEach((wp_del) => {
                    const wp_elem = window.document.getElementById(wp_del.id);
                    wp_elem.remove();
                });
            }

            // Fix icons and placeholders
            const originElem = window.document.getElementById(this.waypoints[ 0 ].id);
            originElem.children[ 0 ].lastElementChild.className = "icon starting";
            (originElem.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose starting point";

            const destinationElem = window.document.getElementById(this.waypoints[ 1 ].id);
            destinationElem.children[ 0 ].lastElementChild.className = "icon destination";
            (destinationElem.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose destination";
        }

        // If two or more inputs are filled: delete empty inputs and remain only filled inputs and add "Add destionation" option
        if (filledWaypoints.length >= 2) {
            this.waypoints = this.waypoints.filter(wp => !isEmpty(wp.value));
            emptyWaypoints.forEach((wp_del) => {
                const wp_elem = window.document.getElementById(wp_del.id);
                wp_elem.remove();
            });

            // Fix icons and placeholders
            this.waypoints.forEach((wp, idx) => {
                const elem = window.document.getElementById(wp.id);
                if (idx == this.waypoints.length - 1) {
                    elem.children[ 0 ].lastElementChild.className = "icon destination";
                    (elem.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose destination";
                } else {
                    if (idx == 0 && elem.children[ 0 ].childElementCount == 2) elem.children[ 0 ].removeChild(elem.children[ 0 ].getElementsByClassName("icon-dots")[ 0 ]);
                    elem.children[ 0 ].lastElementChild.className = "icon starting";
                    const input = elem.children[ 1 ].children[ 0 ] as HTMLInputElement;
                    input.placeholder = idx == 0 ? "Choose starting point" : "Choose waypoint";
                }
            });
        }

        // Remove previous routing layer
        this.removeRoutingLayer();

        if (this._activePopup) {
            this._activePopup.remove();
            this._activePopup = null;
        }

        // Re-render waypoints layer
        this.addWaypointsLayer();

        // Check number of waypoints to display reverse button and remove bin
        const showReverse = filledWaypoints.length <= 2;
        if (showReverse) {
            const reverseBtn = window.document.getElementById("directions-reverse-button");
            reverseBtn?.classList.remove("hide");

            const binWrappers = window.document.getElementsByClassName("waypoint");
            Array.from(binWrappers).forEach((waypointInput: any) => {
                waypointInput.children[ 2 ].classList.remove("show");
                waypointInput.removeEventListener("mouseover", this.mouseOverListener);
                waypointInput.removeEventListener("mouseout", this.mouseOutListener);

                // Remove as referências após a remoção
                delete waypointInput._mouseOverListener;
                delete waypointInput._mouseOutListener;
            });
        }
    };

    // Input handlers

    keydown = () => {
        const searchInput = (document.getElementById("search-input") as HTMLInputElement);
        if (searchInput && searchInput.value) {
            const searchIcon = (document.getElementById("search-icon") as HTMLElement);
            searchIcon.style.display = "none";
            const searchLoading = (document.getElementById("search-loading") as HTMLElement);
            searchLoading.classList.add("nmapsgl-spinner");
            this.search(searchInput, this._resultsWrapper, true, this.showResult)
                .then(() => {
                    // Update UI
                    const searchIcon = (document.getElementById("search-icon") as HTMLElement);
                    searchIcon.style.display = "block";
                    const searchLoading = (document.getElementById("search-loading") as HTMLElement);
                    searchLoading.classList.remove("nmapsgl-spinner");
                    this._categoriesWrapper.classList.remove("show");
                    this._historyWrapper.classList.remove("show");
                    this._resultsWrapper.classList.add("show");
                });
        }
    };

    updateInput = (value) => {
        const valueTrimmed = value.trim();
        // Update clear button
        const clearButton = document.getElementById("search-clear");
        const visibility = valueTrimmed !== "" ? "visible" : "hidden";
        clearButton.style.visibility = visibility;
        // Update search icon
        const searchButton = document.getElementById("search-icon");
        valueTrimmed !== "" ? searchButton.classList.add("show") : searchButton.classList.remove("show");
        valueTrimmed === "" && this._resultsWrapper.classList.remove("show");
    };

    clear = () => {
        // Clear input
        const input = (document.getElementById("search-input") as HTMLInputElement);
        input.value = "";
        this.updateInput(input.value);
        // Clear search
        this.searchResults = [];
        if (this._map.getSource(resultSourceName)) {
            this._map.removeLayer(resultLayerName);
            this._map.removeSource(resultSourceName);
        }
        // Update UI
        this.getHistory();
        this.displayDropdown(true);
        this._resultsWrapper.classList.remove("show");
    };

    updateWaypointInput = (elem, focus, renderLayer = true) => {
        const input = elem.children[ 0 ] as HTMLInputElement;
        const valueTrimmed = input.value.trim();

        // Update icon visibility
        const clearIcon = elem.children[ 1 ];
        const searchIcon = elem.children[ 3 ];

        if (valueTrimmed === "") {
            clearIcon.classList.remove("show");
            searchIcon.classList.add("show");
            if (focus) {
                input.focus();
                input.click();
            }
            // Adjust waypoints layer
            if (renderLayer) this.addWaypointsLayer();
        } else {
            clearIcon.classList.add("show");
            searchIcon.classList.remove("show");
        }
    };

    clearWaypoint = (e) => {
        const waypointId = e.target.parentElement.parentElement.id;
        const wpIdx = this.waypoints.findIndex(wp => wp.id == waypointId);
        this.waypoints[ wpIdx ].value = {};

        // Clear input
        const input = e.target.parentElement.children[ 0 ];
        input.value = "";
        this.updateWaypointInput(e.target.parentElement, true, false);
    };

    search = (inputEl, container, searchResults, callback) => {
        this.searchResults = [];
        const searchInput = inputEl.value;

        if (this.controller) this.controller.abort();
        this.controller = new AbortController();

        // Initialize request
        let request: Promise<GetResourceResponse<any>>;

        if (/(-?\d+\.?\d*)[, ]+(-?\d+\.?\d*)[ ]*$/.test(searchInput)) {
            const coords = searchInput.split(/[\s(,)?]+/).map(c => parseFloat(c));
            request = reverseGeocode(coords, this.controller, this.options.limit);
        } else {
            const center = this._map.getCenter();
            request = genericSearch(searchInput, center, this.controller, this.options.limit);
        }

        return request
            .then(({ data }) => {
                if (data?.results.length) {
                    this.searchResults = data.results;
                    render(container, this.renderResults(inputEl, searchResults, callback));
                } else {
                    const message = html`
                        <div class="message-wrapper">
                            <div class="message-title"> We couldn’t find any results </div>
                            <div class="message-description">
                                Please confirm if the input text it’s spelled correctly. 
                                Try adding more complementing information.
                            </div>
                        </div>
                    `;
                    render(container, message);
                }
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    const message = html`
                        <div class="message-wrapper">
                            <div class="message-title"> There was an error reaching the server </div>
                            <div class="message-description">
                                Please try again
                            </div>
                        </div>
                    `;
                    render(container, message);
                }
            })
            .finally(() => this.controller = null);
    };

    findNearByCategorie = (categorie) => {
        findNearBy(this._map.getCenter(), [ categorie.id ], 10000, 10)
            .then(({ data }) => {
                this.searchResults = data.results;
                this.addResultsLayer();
                render(this._resultsWrapper, this.renderCategoriesResults(categorie.name, false));
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    render(this._resultsWrapper, this.renderCategoriesResults(categorie.name, true));
                }
            })
            .finally(() => {
                this._searchWrapper.classList.add("hide");
                this._historyWrapper.classList.remove("show");
                this._categoriesWrapper.classList.remove("show");
                if (this._categoriesWrapper.classList.contains("all-categories")) this._categoriesWrapper.classList.remove("all-categories");
                this._resultsWrapper.classList.add("show");
            });
    };

    showResult = (inputEl, result, fromPopup = false, center = false) => {
        // Remove temporary pois layer if it's render
        if (this._map.getSource(poisSourceName)) {
            this._map.removeLayer(poisLayerName);
            this._map.removeSource(poisSourceName);
        }

        // Save to Session Storage
        this.addHistory(result);

        if (center) {
            this._map.jumpTo({
                center: result.location,
                zoom: 16
            });
        } else {
            this._map.flyTo({
                center: result.location,
                zoom: 16,
                speed: 1.3
            });
        }

        if (result.location) this.addResultLayer(result);

        // Clear input
        inputEl.value = "";
        this.updateInput(inputEl.value);

        // Render result detail
        this._searchWrapper.classList.add("hide");
        this.currentResult = result;
        this.renderResultDetail(this._resultsWrapper, fromPopup);
        this._resultsWrapper.classList.add("show");

        // Get streetview image to result
        if (this.mapillary_token) {
            const x_dist = 0.00025;
            const y_dist = 0.00025;
            const bbox = [ result.location.lng - x_dist, result.location.lat - y_dist, result.location.lng + x_dist, result.location.lat + y_dist ];
            const params = { "fields": "id", "bbox": bbox.join(","), "limit": 1 };
            this.currentStreetView = null;
            const streetviewEl = (document.getElementById("streetview-wrapper") as HTMLElement);
            if (streetviewEl) streetviewEl.style.display = "none";
            MapillaryService.searchStreetViewImage(params, this.mapillary_token, new AbortController())
                .then((res) => {
                    if (res.data.length) return res.data[ 0 ][ "id" ];
                })
                .then((image_id) => {
                    if (image_id)
                        MapillaryService.getStreetViewImage(image_id, { "fields": "id,sequence,thumb_256_url" }, this.mapillary_token, new AbortController())
                            .then((res) => {
                                this.currentStreetView = res;
                                const streetviewElChild = (streetviewEl.children[ 0 ] as HTMLElement);
                                streetviewElChild.className = "icon-loading";
                                // Make sure is on search mode
                                const resultDetail = window.document.getElementsByClassName("result-detail");
                                if (resultDetail.length) {
                                    streetviewEl.style.display = "block";
                                    setTimeout(() => {
                                        if (this.currentStreetView) {
                                            streetviewEl.style.backgroundImage = `url(${this.currentStreetView.thumb_256_url})`;
                                            streetviewElChild.className = "icon";
                                        }
                                    }, 500);
                                }
                            });
                })
                .catch(() => {
                    console.error("Error fetching Mapillary API.");
                });
        }
    };

    addRoutingData = (routingCriteria) => {
        // Display directions UI
        if (!window.document.getElementById("waypoints")) {
            this.renderDirections();
        }

        // -------------- Update waypoints and UI --------------
        const waypoints = routingCriteria.waypoints;
        if (this.waypoints.length != waypoints.length) {
            const difference = waypoints.length - this.waypoints.length;
            for (let i = 0; i < difference; i++) {
                this.addWaypointInput();
            }
        }
        waypoints.forEach((wp, i) => {
            this.waypoints[ i ].value = wp;
            // Change input
            const wpElem = window.document.getElementById(this.waypoints[ i ].id);
            const inputEl = wpElem.children[ 1 ].children[ 0 ] as HTMLInputElement;
            inputEl.value = wp.formatted_address.replace(/\n/g, ", ");
            inputEl.parentElement.children[ 1 ].classList.add("show");
            inputEl.parentElement.children[ 3 ].classList.remove("show");
        });

        // Fix icons and placeholders
        this.waypoints.forEach((wp, idx) => {
            const elem = window.document.getElementById(wp.id);
            elem.classList.remove("add-waypoint");
            if (idx !== this.waypoints.length - 1) {
                elem.children[ 0 ].lastElementChild.className = "icon starting";
                const input = elem.children[ 1 ].children[ 0 ] as HTMLInputElement;
                input.placeholder = idx == 0 ? "Choose starting point" : "Choose waypoint";
            } else {
                elem.children[ 0 ].lastElementChild.className = "icon destination";
                (elem.children[ 1 ].children[ 0 ] as HTMLInputElement).placeholder = "Choose destination";
            }
            if (idx !== 0 && !elem.querySelector("div.icon-dots")) {
                elem.children[ 0 ].prepend(html.node`<div class="icon-dots"></div>`);
            }
        });

        // Fix reverse and bin 
        if (this.waypoints.length > 2) {
            // Hide reverse button
            const reverseBtn = window.document.getElementById("directions-reverse-button");
            reverseBtn.classList.add("hide");

            // Add show bin icon on hover
            const binWrappers = window.document.getElementsByClassName("waypoint");
            Array.from(binWrappers).forEach((waypointInput: any) => {
                waypointInput.addEventListener("mouseover", this.mouseOverListener);
                waypointInput.addEventListener("mouseout", this.mouseOutListener);
            });
        }

        // -------------- Update profile and UI --------------
        const profilesKeys = Object.keys(profiles);
        const matchingKey = profilesKeys.find((key) => {
            const profile = profiles[ key ];
            return profile.mode === routingCriteria.profile.mode && profile.transportation === routingCriteria.profile.transportation;
        });
        this.activeProfile = matchingKey || profilesKeys[ 0 ];
        const profileElems = window.document.getElementsByClassName("profile-elem");
        Array.from(profileElems).forEach((pe) => {
            pe.classList.remove("active");
            pe.id === `profile-${this.activeProfile}` && pe.classList.add("active");
        });

        // -------------- Update leave type --------------
        this.leave_type = routingCriteria.departure_time < Math.floor(Date.now() / 1000) ? "Leave now" : "Depart at";

        const container = window.document.getElementById("directions-options");
        if (!container.classList.contains("show")) {
            container.classList.add("show");
            render(container, this.renderOptionsWrapper);
        }

        if (this.leave_type !== "Leave now") {
            const leavePicker = window.document.getElementsByClassName("leave-picker")[ 0 ];
            leavePicker.classList.add("show");

            const date = new Date(routingCriteria.departure_time * 1000);
            this.currentDate = date;
            this.currentTime = `${date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`;
            this.renderLeaveDate();
            (window.document.getElementById("leave-time-input") as HTMLInputElement).value = this.currentTime;
            this.addTimeInputHandler();
        }

        // -------------- Update routing options --------------
        avoidOptions.forEach((aop) => {
            if (aop.value in routingCriteria.profile) this.currentAvoids[ aop.value ] = routingCriteria.profile[ aop.value ];
        });

        // Get waypoints wrapper
        const waypointDirectionsWrapper = window.document.getElementById("waypoints-directions-results");
        waypointDirectionsWrapper.classList.add("show");
        render(waypointDirectionsWrapper, html`<div id="nmapsgl-directions-loading-wrapper"><div class="nmapsgl-icon-loading"></div></div>`);
        const loadingEl = document.getElementById("nmapsgl-directions-loading-wrapper");
        if (loadingEl) loadingEl.classList.add("nmapsgl-directions-loading-wrapper-active");

        // Store current routing
        this.currentRouting = routingCriteria;
    };

    showRoutingResult = (routingResult) => {
        // Get waypoints wrapper
        const waypointDirectionsWrapper = window.document.getElementById("waypoints-directions-results");

        // Update current routing
        this.routingResults = routingResult;
        /* eslint-disable */
        const event = new Event('changeDirectionsValue');
        /* eslint-enable */
        this.eventTarget.dispatchEvent(event);

        // Show add waypoint input
        if (this.waypoints.every(wp => !isEmpty(wp.value))) this.addWaypointInput();

        // Render alternatives
        render(waypointDirectionsWrapper, this.renderAlternatives(routingResult));
        // Add tooltip to alternative detail button
        [ "result", routingResult.alternatives.map((_, idx) => `alternative-${idx}`) ].forEach((elem) => {
            const htmlElem = window.document.getElementById(`arrow-right-${elem}`);
            addTooltip(htmlElem, "More details", "bottom");
        });

        onceLoaded(this._map, () => {
            this.renderWaypointsLayers();
            this.addRoutingLayer(routingResult);
        });
    };

    addWaypoint = (inputEl, result) => {
        // Change input
        inputEl.value = result.formatted_address.replace(/\n/g, ", ");
        inputEl.parentElement.children[ 1 ].classList.add("show");
        inputEl.parentElement.children[ 3 ].classList.remove("show");

        // Hide results
        const resultsEl = window.document.getElementById("waypoints-search-results");
        resultsEl.classList.remove("show");

        // Add waypoint
        const waypointEl = inputEl.parentElement.parentElement;
        const wpIdx = this.waypoints.findIndex(wp => wp.id == waypointEl.id);
        if (wpIdx != -1) this.waypoints[ wpIdx ].value = result;

        // Update waypoints layer
        this.addWaypointsLayer();
    };

    updateProfile = (profile) => {
        const profileElems = window.document.getElementsByClassName("profile-elem");
        Array.from(profileElems).forEach((pe) => {
            pe.classList.remove("active");
            pe.id === `profile-${profile}` && pe.classList.add("active");
        });
        this.activeProfile = profile;
        // Remove routing layer
        this.removeRoutingLayer();
        if (this._activePopup) {
            this._activePopup.remove();
            this._activePopup = null;
        }
        // Update waypoints layer
        this.addWaypointsLayer();
    };

    reverseWaypoints = () => {
        const tmpValue = this.waypoints[ 0 ].value;
        this.waypoints[ 0 ].value = this.waypoints[ 1 ].value;
        this.waypoints[ 1 ].value = tmpValue;

        const wpElemStart = window.document.getElementById(this.waypoints[ 0 ].id).children[ 1 ];
        const wpElemStartInput = wpElemStart.children[ 0 ] as HTMLInputElement;
        wpElemStartInput.value = this.waypoints[ 0 ].value[ "formatted_address" ] || "";
        wpElemStartInput.placeholder = "Choose starting point";
        this.updateWaypointInput(wpElemStart, false);

        const wpElemDest = window.document.getElementById(this.waypoints[ 1 ].id).children[ 1 ];
        const wpElemDestInput = wpElemDest.children[ 0 ] as HTMLInputElement;
        wpElemDestInput.value = this.waypoints[ 1 ].value[ "formatted_address" ] || "";
        wpElemDestInput.placeholder = "Choose destination";
        this.updateWaypointInput(wpElemDest, false);

        // Update waypoints layer
        this.addWaypointsLayer();
    };

    changeAlternative = (id) => {
        this._selectedAlternative = id;

        // Update popup
        this.createAlternativePopup(id);

        // Change alternative if alternatives wrapper is rendered
        const alternativeElems = window.document.getElementsByClassName("nmapsgl-ctrl-directions-alternative-wrapper");
        Array.from(alternativeElems).forEach((pe) => {
            pe.classList.remove("active");
            pe.id === `waypoint-${id}` && pe.classList.add("active");
        });

        // Change alternative detail if alternative detail is rendered
        const alternativeDetailElems = window.document.getElementsByClassName("alternative-detail");
        if (alternativeDetailElems.length) {
            let result;
            if (id === "result") result = this.routingResults[ "result" ];
            else {
                const idx = id.split("-")[ 1 ];
                result = this.routingResults[ "alternatives" ][ idx ];
            }
            this.renderAlternativeDetail(result, id);
        }

        // Update Layers
        const style = this._map.getStyle();
        style.layers.forEach((layer) => {
            if (routingSourceName === layer[ "source" ]) {
                if (layer.id.startsWith("routing")) {
                    this._map.setPaintProperty(layer.id, "line-color", "#A8A8A8");
                    if (layer.id !== `routing-${id}`) {
                        this._map.moveLayer(layer.id, `routing-${id}`);
                    }
                }
                if (layer.id.includes("traffic")) {
                    const feature = this._geojson.features.find(f => f.properties.name == layer.id);
                    if (feature) {
                        const prop = feature.properties;
                        this._map.setPaintProperty(layer.id, "line-color", this.getTrafficColor(prop.severity, prop.alternative));
                    }
                }
            }
        });
        this._map.setPaintProperty(`routing-${id}`, "line-color", "#4ca0fe");
        this._map.moveLayer(waypointsLayerName);
        this._map.moveLayer(routingLayerName);
    };

    showHistoryResult = (result) => {
        this._categoriesWrapper.classList.remove("show");
        this._historyWrapper.classList.remove("show");
        if (this.searchHistory.length > 0) this._resultsWrapper.classList.add("show");
        result.distance = getDistance(this._map.getCenter().toArray(), [ result.location.lng, result.location.lat ], { units: "meters" });
        const input = (document.getElementById("search-input") as HTMLInputElement);
        this.showResult(input, result);
    };

    getResult = (coordinates, type, fromPopup) => {
        if (this.currentToast) this.currentToast.hideToast();

        return reverseGeocode(coordinates, this.controller, 1, type)
            .then(({ data }) => {
                if (data?.results.length) {
                    this.searchResults = data.results;
                    const input = (document.getElementById("search-input") as HTMLInputElement);
                    this.showResult(input, data.results[ 0 ], fromPopup);
                    // Update UI
                    const searchIcon = (document.getElementById("search-icon") as HTMLElement);
                    searchIcon.style.display = "block";
                    const searchLoading = (document.getElementById("search-loading") as HTMLElement);
                    searchLoading.classList.remove("nmapsgl-spinner");
                    this._categoriesWrapper.classList.remove("show");
                    this._historyWrapper.classList.remove("show");
                    this._resultsWrapper.classList.add("show");
                    return true;
                } else {
                    this.currentToast = Toastify({
                        node: html.node`<div class="nmapsgl-toast-content"> Couldn't complete action</div>`,
                        ...toastDefinitions
                    });
                    this.currentToast.showToast();
                    return false;
                }
            })
            .catch((e) => {
                if (e.name !== "AbortError") {
                    this.currentToast = Toastify({
                        node: html.node`<div class="nmapsgl-toast-content">Service Unavailable</div>`,
                        ...toastDefinitions
                    });
                    this.currentToast.showToast();
                    return false;
                }
            })
            .finally(() => {
                this.controller = null;
            });
    };

    share = () => {
        this._modal.classList.add("show");
        render(this._modal, this.renderShare());
        document.getElementById("defaultOpen").click();
    };

    showStreetView = () => {
        this.streetViewWindowEl.classList.remove("nmapsgl-streetview-minimize");
        this.streetViewWindowEl.classList.toggle("nmapsgl-streetview-mappilary-window-full");
        this.closeEl.style.display = "block";

        const loadingEl = document.getElementById("nmapsgl-streetview-loading-wrapper");
        if (loadingEl) loadingEl.classList.add("nmapsgl-streetview-loading-wrapper-active");

        this.viewer.moveTo(this.currentStreetView.id)
            .finally(() => {
                if (loadingEl) loadingEl.classList.remove("nmapsgl-streetview-loading-wrapper-active");
            });
    };

    getMapillaryImage = (location) => {
        const x_dist = 0.00025;
        const y_dist = 0.00025;
        const bbox = [ location.lng - x_dist, location.lat - y_dist, location.lng + x_dist, location.lat + y_dist ];
        const params = { "fields": "id", "bbox": bbox.join(","), "limit": 1 };

        return MapillaryService.searchStreetViewImage(params, this.mapillary_token, new AbortController())
            .then((res) => { if (res.data.length) return res.data[ 0 ][ "id" ]; })
            .then((image_id) => {
                if (image_id) {
                    return MapillaryService.getStreetViewImage(image_id, { "fields": "id,sequence,thumb_256_url" }, this.mapillary_token, new AbortController())
                        .then((res: MapillaryService.ImageResponse) => { return res; });
                }
            });
    };

    changeLeaveType = (e) => {
        const currentLeaveType = e.target.innerText;
        if (currentLeaveType != this.leave_type) {
            // Update leave type
            this.leave_type = currentLeaveType;
            const leaves = window.document.getElementsByClassName("leave-btn");
            Array.from(leaves).forEach(leave => leave.classList.remove("active"));
            e.target.classList.add("active");

            // Check if show display leave date picker
            const leavePicker = window.document.getElementsByClassName("leave-picker")[ 0 ];
            this.leave_type == "Leave now" ? leavePicker.classList.remove("show") : leavePicker.classList.add("show");

            // Reset leave time and date
            const now = new Date();
            this.currentDate = now;
            this.currentTime = `${now.getHours()}:${(now.getMinutes() < 10 ? "0" : "") + now.getMinutes()}`;
            this.renderLeaveDate();
            (window.document.getElementById("leave-time-input") as HTMLInputElement).value = this.currentTime;

            this.addTimeInputHandler();

            // Recalculate route
            this.addWaypointsLayer();
        }
    };

    changeUnits = (e) => {
        this.units = e.target.value;

        // Update alternatives HTML
        const distances = Array.from(window.document.getElementsByClassName("alternative-distance"));
        distances.forEach((distance) => {
            const newDistance = convertDistance((distance as HTMLElement).innerText, true);
            distance.innerHTML = newDistance;
        });

        // Update popup HTML
        const distancePopup = Array.from(window.document.getElementsByClassName("popup-distance"));
        distancePopup.forEach((distance) => {
            const newDistance = convertDistance((distance as HTMLElement).innerText, true);
            distance.innerHTML = newDistance;
        });
    };

    changeAvoids = (e) => {
        // Update avoids
        this.currentAvoids[ e.target.value ] = !e.target.checked;
        this.addWaypointsLayer();
    };

    // UI Handlers

    displayDropdown = (display) => {
        const input = (document.getElementById("search-input") as HTMLInputElement);
        if (display && this.searchResults.length == 0 && input.value == "") {
            if (this.categories?.length > 0) this._categoriesWrapper.classList.add("show");
            if (this.searchHistory.length > 0) this._historyWrapper.classList.add("show");
        } else {
            !this._categoriesWrapper.classList.contains("all-categories") && this._categoriesWrapper.classList.remove("show");
            this._historyWrapper.classList.remove("show");
        }
    };

    displayCategories = (display, close: boolean = false) => {
        if (display) {
            this._searchWrapper.classList.add("hide");
            this._historyWrapper.classList.remove("show");
            this._categoriesWrapper.classList.add("all-categories");
            render(this._categoriesWrapper, this.renderAllCategories());
        } else {
            this._searchWrapper.classList.remove("hide");
            this._categoriesWrapper.classList.remove("all-categories");
            if (close) {
                this._categoriesWrapper.classList.remove("show");
                this._historyWrapper.classList.remove("show");
            } else {
                if (this.searchHistory.length > 0) this._historyWrapper.classList.add("show");
            }
            render(this._categoriesWrapper, this.renderCategories(false));
        }
    };

    displayDirectionsDropdown = (e, display) => {
        const waypointsSearchWrapper = window.document.getElementById("waypoints-search-results");
        if (e && e.target.value == "" && display) {
            const inputEl = e.target;
            waypointsSearchWrapper.classList.add("show");
            const addWaypointFromHistory = (result) => {
                this.addWaypoint(inputEl, result);
                waypointsSearchWrapper.classList.remove("show");
                this.updateWaypointInput(e.target.parentElement, false);
            };

            render(waypointsSearchWrapper, this.renderHistory(false, false, true, addWaypointFromHistory, true));
        } else {
            if (waypointsSearchWrapper) waypointsSearchWrapper.classList.remove("show");
        }
    };

    hideCategorieResult = (close: boolean) => {
        if (this._map.getSource(poisSourceName)) {
            this._map.removeLayer(poisLayerName);
            this._map.removeSource(poisSourceName);
        }
        const instance = this._map.getLayerInstance(POIsLayer.id);
        instance.off("change", this.poisLayerHandler);
        instance.setState(this.hasPoisLayer);
        this.hideResult(close);
    };

    hideResult = (close: boolean = false) => {
        if (this._map.getSource(resultSourceName)) {
            this._map.removeLayer(resultLayerName);
            this._map.removeSource(resultSourceName);
        }
        this.searchResults = [];
        this.currentResult = null;
        this.currentStreetView = null;
        this._searchWrapper.classList.remove("hide");
        this._resultsWrapper.classList.remove("show");
        render(this._categoriesWrapper, this.renderCategories(false));
        render(this._resultsWrapper, html``);
        const streetviewEl = (document.getElementById("streetview-wrapper") as HTMLElement);
        if (streetviewEl) streetviewEl.style.display = "none";
        if (close) {
            this._historyWrapper.classList.remove("show");
            this._categoriesWrapper.classList.remove("show");
        } else {
            if (this.searchHistory.length > 0) this._historyWrapper.classList.add("show");
            this._categoriesWrapper.classList.add("show");
        }
    };

    closeDirections = () => {
        // Remove waypoint layers
        if (this._map.getSource(waypointsSourceName)) {
            if (this._map.getLayer(waypointsLayerName)) this._map.removeLayer(waypointsLayerName);
            if (this._map.getLayer(routingLayerName)) this._map.removeLayer(routingLayerName);
            this._map.removeSource(waypointsSourceName);
        }

        this.removeRoutingLayer();
        if (this._maneuverMarker) this._maneuverMarker.remove();
        if (this._activePopup) {
            this._activePopup.remove();
            this._activePopup = null;
        }

        // Clear waypoints
        this.waypoints = [
            { "id": "waypoint-origin", "value": {} },
            { "id": "waypoint-destination", "value": {} }
        ];
        this.waypointNumber = 1;

        // Clear options
        avoidOptions.forEach(ao => this.currentAvoids[ ao.value ] = true);
        this.units = this.options.units;

        // Reset leave type
        this.leave_type = "Leave now";

        // Clear current routing
        this.currentRouting = null;
        /* eslint-disable */
        const event = new Event('changeDirectionsValue');
        /* eslint-enable */
        this.eventTarget.dispatchEvent(event);

        render(this._container, this.renderControl);
    };

    showHistory = (showAll) => {
        render(this._historyWrapper, this.renderHistory(false, showAll, false, this.showHistoryResult, false));
    };

    onMouseOverCategorieResult = (r) => {
        this._map.setLayoutProperty(poisLayerName, "icon-size", [
            "case", [ "all",
                [ "==", [ "get", "name" ], r.name ],
                [ "==", [ "get", "lat" ], r.location.lat ],
                [ "==", [ "get", "lng" ], r.location.lng ],
            ], 0.9, 0.8
        ]);
        this._map.setLayoutProperty(poisLayerName, "text-size", [
            "case", [ "all",
                [ "==", [ "get", "name" ], r.name ],
                [ "==", [ "get", "lat" ], r.location.lat ],
                [ "==", [ "get", "lng" ], r.location.lng ],
            ], 14, 13
        ]);
        this._map.setPaintProperty(poisLayerName, "text-color", [
            "case", [ "all",
                [ "==", [ "get", "name" ], r.name ],
                [ "==", [ "get", "lat" ], r.location.lat ],
                [ "==", [ "get", "lng" ], r.location.lng ],
            ], "#2B8FFF", "rgba(92, 92, 92, 1)"
        ]);
    };

    onMouseLeaveCategorieResult = () => {
        this._map.setLayoutProperty(poisLayerName, "icon-size", 0.8);
        this._map.setLayoutProperty(poisLayerName, "text-size", 13);
        this._map.setPaintProperty(poisLayerName, "text-color", "rgba(92, 92, 92, 1)");
    };

    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        if (this.currentToast) this.currentToast.hideToast();
        this.currentToast = Toastify({
            node: html.node`<div class="nmapsgl-toast-content">Copied</div>`,
            ...toastDefinitions
        }).showToast();
    };

    openTab = (evt, tab) => {
        const tabcontent = (document.getElementsByClassName("tabcontent") as HTMLCollection);
        Array.from(tabcontent).forEach(tb => (tb as HTMLElement).style.display = "none");

        const tablinks = document.getElementsByClassName("tablinks");
        Array.from(tablinks).forEach(tl => (tl as HTMLElement).className = tl.className.replace(" active", ""));

        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    };

    updateListCounter = () => {
        const input = (document.getElementById("list") as HTMLInputElement);
        const counter = (document.getElementById("list-counter") as HTMLInputElement);
        counter.innerHTML = `${input.value.length}/50`;
    };

    private onCloseClick = () => {
        const sequenceComponent: SequenceComponent = this.viewer.getComponent("sequence");
        sequenceComponent.stop();
        this.streetViewWindowEl.classList.add("nmapsgl-streetview-minimize");
        this.streetViewWindowEl.classList.toggle("nmapsgl-streetview-mappilary-window-full");
        this.closeEl.style.display = "none";
    };

    hasOrigin = () => {
        return !isEmpty(this.waypoints[ 0 ].value);
    };

    hasDestination = () => {
        return !isEmpty(this.waypoints[ 1 ].value);
    };

    hasWapoint = (id) => {
        return !isEmpty(this.waypoints.find(wp => wp.id == id)?.value);
    };

    maxWaypoints = () => this.waypoints.filter(wp => !isEmpty(wp.value)).length == maxWaypointsSize;

    canRemoveWaypoint = () => this.waypoints.filter(wp => !isEmpty(wp.value)).length > 2;

    _addPoint(point: LngLat, direction) {
        // Normalize coordinates
        // eslint-disable-next-line prefer-const
        let { lat, lng } = point;
        if (lng < -180) lng = lng + 180;
        else if (lng > 180) lng = lng - 180;
        const coordinates = [ lat.toFixed(6), lng.toFixed(6) ];

        if (this.controller) this.controller.abort();
        this.controller = new AbortController();

        reverseGeocode(coordinates, this.controller, 1)
            .then(({ data }) => {
                const result = data.results[ 0 ];
                if (!window.document.getElementById("waypoints")) {
                    this.renderDirections();
                }

                const wpId = direction == "waypoint" ? this.waypointNumber - 1 : direction;
                const wpElem = window.document.getElementById(`waypoint-${wpId}`);

                if (direction == "waypoint") {
                    // Fix icons
                    const waypointIdx = this.waypoints.findIndex(wp => wp.id == `waypoint-${wpId}`);
                    if (waypointIdx > 1) {
                        const previousWaypoint = this.waypoints[ waypointIdx - 1 ];
                        const previousSibling = window.document.getElementById(previousWaypoint.id);
                        previousSibling.children[ 0 ].lastElementChild.className = "icon starting";
                        (previousSibling.children[ 1 ].children[ 1 ] as HTMLInputElement).placeholder = "Add destination";
                    }
                    if (wpElem) {
                        const newElement = html.node`
                            <div>
                                <div class="icon-dots"></div>
                                <div class="icon destination"></div>
                            </div>
                        `;
                        wpElem.replaceChild(newElement, wpElem.children[ 0 ]);
                        wpElem.classList.remove("add-waypoint");
                    }

                    // Add show bin icon on hover
                    const binWrappers = window.document.getElementsByClassName("waypoint");
                    Array.from(binWrappers).forEach((waypointInput: any) => {
                        waypointInput.addEventListener("mouseover", this.mouseOverListener);
                        waypointInput.addEventListener("mouseout", this.mouseOutListener);
                    });

                    // Hide reverse button
                    const reverseBtn = window.document.getElementById("directions-reverse-button");
                    reverseBtn.classList.add("hide");
                }

                if (wpElem) {
                    const inputElem = wpElem.children[ 1 ].children[ 0 ] as HTMLInputElement;
                    this.addWaypoint(inputElem, result);
                }
            }).catch((e) => {
                console.error(e);
            })
            .finally(() => this.controller = null);
    }

    _removePoint(point: Point) {
        const features = this._map.queryRenderedFeatures(point, {
            layers: [ "waypoints_layer", "routing_layer" ]
        });
        if (features.length > 0) {
            const properties = features[ 0 ].properties;
            const elem = window.document.getElementById(properties.id);
            this.removeWaypoint(elem);
        }
    }

    callbackUserLocation = (callback) => {
        const your_location = {
            "formatted_address": "Your location",
            "location": {},
            "name": "Your location"
        };

        const locationWrapper = window.document.getElementsByClassName("location-wrapper")[ 0 ];
        locationWrapper.classList.add("loading");
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.disabledUserLocation = false;
                    locationWrapper.classList.remove("loading");
                    your_location.location[ "lat" ] = position.coords.latitude;
                    your_location.location[ "lng" ] = position.coords.longitude;
                    callback(your_location);
                },
                () => {
                    this.disabledUserLocation = true;
                    locationWrapper.classList.add("disabled");
                    locationWrapper.classList.remove("loading");
                });
        }
    };

    checkUserLocation = () => {
        navigator.permissions.query({ name: "geolocation" })
            .then((permissionStatus) => {

                const checkStatus = (status) => {
                    if (status == "denied") {
                        this.disabledUserLocation = true;

                        const elem = window.document.getElementsByClassName("location-wrapper");
                        if (elem.length) {
                            elem[ 0 ].classList.add("disabled");
                        }
                    }
                };

                permissionStatus.onchange = () => checkStatus(permissionStatus.state);
                checkStatus(permissionStatus.state);
            });
    };

    // Funcitons (API, Aux)

    addHistory = (record) => {
        // Check if record is not already stored on Session Storage
        const exists = this.searchHistory.map(i =>
            i.value.name == record.name && i.value.location.lat == record.location.lat && i.value.location.lng == record.location.lng
        ).some(e => e === true);
        if (!exists) {
            if (this.searchHistory.length == 15) this.searchHistory.pop();
            sessionStorage.setItem("search-results", JSON.stringify([ { key: `search-${generateUniqueId()}`, value: record }, ...this.searchHistory ]));
        }
        this.getHistory();
    };

    deleteHistory = (event, key) => {
        event.stopPropagation();
        this.searchHistory = this.searchHistory.filter(sh => sh.key !== key);
        sessionStorage.setItem("search-results", JSON.stringify(this.searchHistory));
        this.getHistory();
    };

    getFormatedAddress = (record) => {
        const escapedName = record.name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(`${escapedName}, \\s * (.*)`);
        const match = record.formatted_address.match(regex);
        return match ? match[ 1 ].trim() : record.formatted_address.trim();
    };

    getResultAddress = (record) => {
        let address = "";
        switch (record.type) {
            case "house_number":
            case "postal_code":
            case "street":
            case "area":
                address = record.areas.sort((a, b) => a.level - b.level).slice(0, 2).map(area => area.name).reverse().join(", ");
                break;
            case "state":
                address = `${record.areas.find(a => a.level == 1).name} `;
                break;
            case "poi":
                address = this.getPOICategorie(record.poi_info.categories[ 0 ]);
                break;
            case "country":
                address = "";
                break;
            default:
                address = "";
        }
        return address;
    };

    getPOICategorie = (categorie) => {
        let label;
        this.categories.forEach((c) => {
            if (c.id == categorie) label = c.name;
            c.children?.forEach((ch) => {
                if (ch.id == categorie) label = ch.name;
            });
        });
        return label;
    };

    getImage = (result) => {
        let image = this.categoriesMap[ "fallback" ]?.image;
        // Find main categorie;
        if (result.type == "poi") {
            const categories = result.poi_info.categories;
            const mainCategories = this.categories.map(t => t.id);
            const intersect = categories.filter(value => mainCategories.includes(value));
            if (intersect.length > 0 && this.categoriesMap[ intersect[ 0 ] ]) image = this.categoriesMap[ intersect[ 0 ] ].image;
        }
        return image;
    };

    addResultsLayer = () => {
        // Deactivate POIs Layer
        const instance = this._map.addLayerInstance(POIsLayer);
        this.hasPoisLayer = instance.isEnabled();
        instance.setState(false);
        instance.on("change", this.poisLayerHandler);

        if (this._map.getSource(poisSourceName)) {
            this._map.removeLayer(poisLayerName);
            this._map.removeSource(poisSourceName);
        }

        this._map.addSource(poisSourceName, {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": this.searchResults.map((rs) => {
                    return {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [ rs.location.lng, rs.location.lat ]
                        },
                        "properties": {
                            "category_id": rs.poi_info.categories[ rs.poi_info.categories.length - 1 ],
                            "name": rs.name,
                            "lat": rs.location.lat,
                            "lng": rs.location.lng
                        }
                    };
                })
            }
        });

        this._map.addLayer({
            "id": poisLayerName,
            "type": "symbol",
            "source": poisSourceName,
            "layout": {
                "icon-image": "poi-category-{category_id}",
                "icon-rotation-alignment": "viewport",
                "icon-anchor": "bottom",
                "text-rotation-alignment": "viewport",
                "text-pitch-alignment": "viewport",
                "text-anchor": "left",
                "icon-size": 0.8,
                "visibility": "visible",
                "symbol-z-order": "source",
                "text-field": [
                    "case",
                    [
                        "all",
                        [ "has", "name_script" ],
                        [
                            "!=",
                            [ "get", "name_script" ],
                            "Latn"
                        ],
                        [ "has", "name_latin" ]
                    ],
                    [
                        "concat",
                        [ "get", "name" ],
                        "\n",
                        [ "get", "name_latin" ]
                    ],
                    [ "get", "name" ]
                ],
                "text-size": 13,
                "text-line-height": 1.4,
                "text-justify": "left",
                "text-offset": [ 1.2, -1.2 ],
                "text-transform": "none",
                "text-max-width": 12,
                "text-padding": 0,
                "text-optional": true
            },
            "paint": {
                "text-color": "rgba(92, 92, 92, 1)",
                "text-halo-color": "rgba(255, 255, 255, 1)",
                "text-halo-width": 1.8
            }
        });

        const coordinates = this.searchResults.map(rs => new LngLat(rs.location.lng, rs.location.lat));
        const bounds = new LngLatBounds(coordinates[ 0 ], coordinates[ 0 ]);
        for (const coord of coordinates) { bounds.extend(coord); }
        this._map.fitBounds(bounds, {
            padding: { top: 100, bottom: 100, left: 400, right: 100 }
        });
    };

    addResultLayer = (result) => {
        if (this._map.getSource(resultSourceName)) {
            this._map.removeLayer(resultLayerName);
            this._map.removeSource(resultSourceName);
        }

        this._map.addSource(resultSourceName, {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": [ {
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [ result.location.lng, result.location.lat ]
                    },
                    "properties": {
                        "name": result.name
                    }
                } ]
            }
        });

        this._map.addLayer({
            "id": resultLayerName,
            "type": "symbol",
            "source": resultSourceName,
            "layout": {
                "icon-image": "custom-marker",
                "text-size": 14,
                "icon-size": 1,
                "text-field": [ "get", "name" ],
                "text-anchor": "left",
                "icon-anchor": "bottom",
                "text-offset": [ 1.2, -1.2 ],
            },
            "paint": {
                "text-color": "#E33300",
                "text-halo-color": "rgba(255, 255, 255, 1)",
                "text-halo-width": 1.8
            }
        });
    };

    renderWaypointsLayers = () => {
        if (this._map.getSource(waypointsSourceName)) {
            if (this._map.getLayer(waypointsLayerName)) this._map.removeLayer(waypointsLayerName);
            if (this._map.getLayer(routingLayerName)) this._map.removeLayer(routingLayerName);
            this._map.removeSource(waypointsSourceName);
        }

        const filteredWaypoints = this.waypoints.filter(wp => !isEmpty(wp.value));
        if (filteredWaypoints.length) {
            this._map.addSource(waypointsSourceName, {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": filteredWaypoints.map((wp) => {
                        return {
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [ wp.value[ "location" ].lng, wp.value[ "location" ].lat ]
                            },
                            "properties": {
                                "id": wp.id,
                                "name": wp.value[ "name" ]
                            }
                        };
                    })
                }
            });

            this._map.addLayer({
                "id": waypointsLayerName,
                "type": "symbol",
                "source": waypointsSourceName,
                "layout": {
                    "icon-image": "waypoint-marker",
                    "text-size": 14,
                    "icon-size": 1,
                    "text-field": [ "get", "name" ],
                    "text-anchor": "left",
                    "icon-anchor": "bottom",
                    "text-offset": [ 1.2, -1.2 ],
                },
                "paint": {
                    "text-color": "#000000",
                    "text-halo-color": "#FFFFFF",
                    "text-halo-width": 1.8
                }
            });

            this._map.persistLayers([ waypointsLayerName ]);

            //Fit bounds
            if (filteredWaypoints.length == 1) {
                this._map.flyTo({
                    center: filteredWaypoints[ 0 ].value[ "location" ],
                    zoom: 16,
                    speed: 1.3
                });
            } else {
                this.fitToWaypoints();
            }

            this._map.addLayer({
                "id": routingLayerName,
                "type": "symbol",
                "source": waypointsSourceName,
                "layout": {
                    "icon-image": "custom-waypoint-marker",
                    "icon-size": 1,
                    "icon-anchor": "bottom",
                    "text-size": 14,
                    "text-field": [ "get", "name" ],
                    "text-anchor": "left",
                    "text-offset": [ 1.2, -1.2 ],
                },
                "paint": {
                    "text-color": "#000000",
                    "text-halo-color": "#FFFFFF",
                    "text-halo-width": 1.8
                },
                "filter": [ "==", "id", filteredWaypoints[ filteredWaypoints.length - 1 ].id ]
            });
            this._map.persistLayers([ routingLayerName ]);
        }
    };

    addWaypointsLayer = () => {
        this.renderWaypointsLayers();

        let departure_time = 0;
        if (this.currentDate && this.currentTime) {
            const date = new Date(this.currentDate.getTime());
            const hour_min = this.currentTime.split(":");
            date.setHours(hour_min[ 0 ]);
            date.setMinutes(hour_min[ 1 ]);
            departure_time = Math.round(date.getTime() / 1000);
        }

        // Reset current result
        this.currentRouting = null;
        /* eslint-disable */
        const event = new Event('changeDirectionsValue');
        /* eslint-enable */
        this.eventTarget.dispatchEvent(event);

        const filteredWaypoints = this.waypoints.filter(wp => !isEmpty(wp.value));
        if (filteredWaypoints.length >= 2 && this.waypoints.slice(0, -1).every(obj => !isEmpty(obj.value))) {
            const waypointDirectionsWrapper = window.document.getElementById("waypoints-directions-results");
            waypointDirectionsWrapper.classList.add("show");

            render(waypointDirectionsWrapper, html`<div id="nmapsgl-directions-loading-wrapper"><div class="nmapsgl-icon-loading"></div></div>`);
            const loadingEl = document.getElementById("nmapsgl-directions-loading-wrapper");
            if (loadingEl) loadingEl.classList.add("nmapsgl-directions-loading-wrapper-active");

            // Get waypoints and normalize coords
            const waypoints = filteredWaypoints.map((wp) => {
                const lng = Number(wp.value[ "location" ].lng);
                if (lng < -180) {
                    wp.value[ "location" ].lng = lng + Number(180);
                }
                if (lng > 180) {
                    wp.value[ "location" ].lng = lng - Number(180);
                }
                return { "location": wp.value[ "location" ], "name": wp.value[ "name" ], "formatted_address": wp.value[ "formatted_address" ] };
            });
            const waypointsLocation = waypoints.map(wp => wp.location);

            // Calculate route
            if (this.controller) {
                this.controller.abort();
            }
            this.controller = new AbortController();

            // Profile
            const profile = { ...profiles[ this.activeProfile ], ...this.currentAvoids };
            delete profile[ "icon" ];

            // Traffic (always request)
            const traffic = true;

            getDirections(waypointsLocation, profile, this.controller, departure_time, true, 2, true, traffic)
                .then(({ data }) => {
                    this.routingResults = data;
                    this.controller = null;
                    if (loadingEl) loadingEl.classList.remove("nmapsgl-directions-loading-wrapper-active");
                    const container = window.document.getElementById("directions-options");
                    if (!container.classList.contains("show")) {
                        container.classList.add("show");
                        render(container, this.renderOptionsWrapper);
                        this.addTimeInputHandler();
                        this.renderLeaveDate();
                    }

                    // Show add waypoint input
                    if (this.waypoints.every(wp => !isEmpty(wp.value))) {
                        this.addWaypointInput();
                    }

                    // Render alternatives
                    render(waypointDirectionsWrapper, this.renderAlternatives(data));
                    // Add tooltip to alternative detail button
                    [ "result", data.alternatives.map((_, idx) => `alternative-${idx}`) ].forEach((elem) => {
                        const htmlElem = window.document.getElementById(`arrow-right-${elem}`);
                        addTooltip(htmlElem, "More details", "bottom");
                    });
                    this.addRoutingLayer(data);

                    // Store current routing
                    this.currentRouting = {
                        profile,
                        waypoints,
                        departure_time,
                        traffic
                    };

                    /* eslint-disable */
                    const event = new Event('changeDirectionsValue');
                    /* eslint-enable */
                    this.eventTarget.dispatchEvent(event);
                })
                .catch((e) => {
                    if (e.name !== "AbortError") {
                        this.controller = null;

                        // Hide options
                        const options = window.document.getElementById("directions-options");
                        if (options) options.classList.remove("show");

                        // Hide symbol layers
                        this._map.setLayoutProperty(waypointsLayerName, "visibility", "none");
                        this._map.setLayoutProperty(routingLayerName, "visibility", "none");

                        // Remove loading
                        if (loadingEl) loadingEl.classList.remove("nmapsgl-directions-loading-wrapper-active");
                        const message = html`
                                <div class="message-wrapper">
                                    <div class="message-icon directions-warning-icon"></div>
                                    <div class="message-description">
                                        Unfortunately we couldn’t calculate directions for this type of transportation.
                                    </div>
                                </div>
                            `;
                        render(waypointDirectionsWrapper, message);
                    }
                });
        }
    };

    loadSource = () => {
        const geojson: SourceSpecification = {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        };

        // Add and set data theme layer/style
        if (!this._map.getSource(routingSourceName)) {
            this._map.addSource(routingSourceName, geojson);
            if (this._geojson) {
                (this._map.getSource(routingSourceName) as GeoJSONSource).setData(this._geojson);
            }
        }
    };

    addRoutingLayer = (data) => {
        // Clear previous geometry
        this._geojson = {
            type: "FeatureCollection",
            features: []
        };

        this.loadSource();

        // Reset variables to populate with new routing
        this._layers = [];
        this._selectedAlternative = "result";

        const geometries = flatten([
            flatten(data.result.legs.map((leg) => {
                return { "name": "result", "geometry": leg.geometry, "eta": data.result.total_time, "distance": data.result.total_distance };
            })),
            flatten((data.alternatives || []).map((c, i) => {
                return c.legs.map((legAlt) => {
                    return { "name": `alternative-${i}`, "geometry": legAlt.geometry, "eta": data.alternatives[ i ].total_time, "distance": data.alternatives[ i ].total_distance };
                });
            }))
        ]);

        geometries.forEach((g) => {
            const features = [];
            const decoded = polyline.decode(g[ "geometry" ], 6).map((c) => {
                return c.reverse();
            });
            decoded.forEach((c) => {
                const previous = features[ features.length - 1 ];

                if (previous) {
                    previous.geometry.coordinates.push(c);
                } else {
                    const segment = {
                        "type": "Feature",
                        "properties": {
                            "name": g[ "name" ]
                        },
                        "geometry": {
                            "type": "LineString",
                            "coordinates": []
                        }
                    };
                    // New segment starts with previous segment's last coordinate.
                    if (previous) segment.geometry.coordinates.push(previous.geometry.coordinates[ previous.geometry.coordinates.length - 1 ]);
                    segment.geometry.coordinates.push(c);
                    features.push(segment);
                }
            });
            this._geojson.features = this._geojson.features.concat(features);
            // Define new layer
            const style = this._map.getStyle();
            const lineColor = this.getCustomColor(this._map.getStyle().name);
            const layer: LayerSpecification = {
                "id": `routing-${g[ "name" ]}`,
                "type": "line",
                "source": routingSourceName,
                "paint": {
                    "line-color": g[ "name" ] === "result" ? "#4ca0fe" : lineColor,
                    "line-width": 4
                },
                "layout": {
                    "visibility": "visible"
                },
                "filter": [ "==", "name", g[ "name" ] ]
            };
            this._layers.push(layer);

            // Check if map has this layer
            if (!this._map.getLayer(`routing-${g[ "name" ]}`)) {
                this._map.addLayer(layer);
                if (g[ "name" ] !== "result" && this._map.getLayer("routing-result")) {
                    this._map.moveLayer(`routing-${g[ "name" ]}`, "routing-result");
                }
                this._map.on("click", `routing-${g[ "name" ]}`, () => {
                    if (this._hoverPopup) {
                        this._hoverPopup.remove();
                    }
                    this.changeAlternative(g[ "name" ]);
                });
            } else {
                style.layers.forEach((layer) => {
                    if (routingSourceName === layer[ "source" ]) {
                        if (layer.id !== "routing-result") {
                            this._map.setPaintProperty(layer.id, "line-color", "#A8A8A8");
                            this._map.moveLayer(layer.id, "routing-result");
                        }
                    }
                });
                this._map.setPaintProperty("routing-result", "line-color", "#4ca0fe");
            }

            // Change the cursor to a pointer when the mouse is over.
            this._map.on("mouseenter", `routing-${g[ "name" ]}`, (e) => {
                if (g[ "name" ] !== this._selectedAlternative) {
                    let result;
                    if (g[ "name" ] === "result") result = this.routingResults[ "result" ];
                    else {
                        const idx = g[ "name" ].split("-")[ 1 ];
                        result = this.routingResults[ "alternatives" ][ idx ];
                    }
                    if (this._hoverPopup) {
                        this._hoverPopup.remove();
                    }
                    const popupContent = this.createHTMLPopup(result, false);
                    this._hoverPopup = new Popup({ anchor: "left", closeButton: false, closeOnClick: false, className: "alternative-popup" })
                        .setLngLat(e.lngLat)
                        .setHTML(popupContent)
                        .addTo(this._map);
                    this._map.getCanvas().style.cursor = "pointer";
                }
            });
            // Change it back to a pointer when it leaves.
            this._map.on("mouseleave", `routing-${g[ "name" ]}`, () => {
                this._map.getCanvas().style.cursor = "";
                if (this._hoverPopup) {
                    this._hoverPopup.remove();
                }
            });
        });

        // Check traffic segments
        const trafficGeometries = flatten([
            flatten(data.result.legs).map((leg) => {
                return flatten((leg[ "traffic" ]?.severity_segments || []).map((traffic_seg) => {
                    return traffic_seg.geometry.map((traffic_geo, i) => {
                        return { "name": `result-traffic-${traffic_seg.severity}-${i}`, "alternative": "result", "geometry": traffic_geo, "severity": traffic_seg.severity };
                    });
                }));
            }),
            flatten((data.alternatives || []).map((c, i) => {
                return c.legs.map((legAlt) => {
                    return flatten((legAlt[ "traffic" ]?.severity_segments || []).map((traffic_seg) => {
                        return traffic_seg.geometry.map((traffic_geo, j) => {
                            return { "name": `alternative-${i}-traffic-${traffic_seg.severity}-${j}`, "alternative": `alternative-${i}`, "geometry": traffic_geo, "severity": traffic_seg.severity };
                        });
                    }));
                });
            }))
        ]).flat(1);

        trafficGeometries.forEach((g) => {
            const features = [];
            const decoded = polyline.decode(g[ "geometry" ], 6).map((c) => {
                return c.reverse();
            });
            decoded.forEach((c) => {
                const previous = features[ features.length - 1 ];

                if (previous) {
                    previous.geometry.coordinates.push(c);
                } else {
                    const segment = {
                        "type": "Feature",
                        "properties": {
                            "name": g[ "name" ],
                            "severity": g[ "severity" ],
                            "alternative": g[ "alternative" ]
                        },
                        "geometry": {
                            "type": "LineString",
                            "coordinates": []
                        }
                    };
                    // New segment starts with previous segment's last coordinate.
                    if (previous) segment.geometry.coordinates.push(previous.geometry.coordinates[ previous.geometry.coordinates.length - 1 ]);
                    segment.geometry.coordinates.push(c);
                    features.push(segment);
                }
            });
            this._geojson.features = this._geojson.features.concat(features);
            const layer: LayerSpecification = {
                "id": `${g[ "name" ]}`,
                "type": "line",
                "source": routingSourceName,
                "paint": {
                    "line-color": this.getTrafficColor(g[ "severity" ], g[ "alternative" ]),
                    "line-width": 4
                },
                "layout": {
                    "visibility": "visible"
                },
                "filter": [ "==", "name", g[ "name" ] ]
            };
            this._layers.push(layer);

            // Check if map has this layer
            if (!this._map.getLayer(`${g[ "name" ]}`)) this._map.addLayer(layer);
        });

        this.createAlternativePopup("result");

        this._map.persistLayers(this._layers.map(l => l.id));

        (this._map.getSource(routingSourceName) as GeoJSONSource).setData(this._geojson);
        this._map.moveLayer(waypointsLayerName);
        this._map.moveLayer(routingLayerName);
    };

    removeRoutingLayer = () => {
        this._layers = [];
        this._selectedAlternative = null;
        const style = this._map.getStyle();
        style.layers.forEach((layer) => {
            if (routingSourceName === layer[ "source" ]) {
                this._map.removeLayer(layer.id);
            }
        });
    };

    checkQueryParams = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const params = {};
        queryParams.forEach((value, key) => params[ key ] = value);
        const hasAllParams = [ "lat", "lng", "type" ].every(key => key in params);
        window.history.replaceState({}, document.title, window.location.pathname);
        if (hasAllParams) {
            this.getResult([ parseFloat(params[ "lat" ]), parseFloat(params[ "lng" ]) ], [ params[ "type" ] ], true);
        }
    };

    isInHistory = (r) => {
        return this.searchHistory.some(sh =>
            sh.value.name == r.name &&
            sh.value.location.lat == r.location.lat &&
            sh.value.location.lng == r.location.lng
        );
    };

    generateWarning = (crosses) => {
        let routeDescription = "This route has ";
        const crossesKeys = Object.keys(crosses).filter(k => k !== "countrycrossing");
        crossesKeys.forEach((key, index) => {
            routeDescription += key;
            if (index < crossesKeys.length - 2) routeDescription += ", ";
            if (index == crossesKeys.length - 2) routeDescription += " and ";
        });
        routeDescription += ".";
        return routeDescription;
    };

    private poisLayerHandler = () => {
        const instance = this._map.getLayerInstance(POIsLayer.id);
        this.hasPoisLayer = instance.isEnabled();
    };

    private getCategories() {
        const categoriesPromisse = makeNMapsRequest({ url: `${getApiUrl()}/v1/places/categories/en`, type: "json" });
        const categoriesMapPromisse = makeNMapsRequest({ url: `${getApiUrl()}/assets/categories.json`, type: "json" });

        return Promise.all([ categoriesPromisse, categoriesMapPromisse ])
            .then((res) => {
                this.categories = res[ 0 ].data?.categories.filter(c => c.tree_visible);
                this.categoriesMap = res[ 1 ].data;
                render(this._categoriesWrapper, this.renderCategories(false));
            });
    }

    private getHistory() {
        this.searchHistory = JSON.parse(sessionStorage.getItem("search-results")) || [];
        if (this.searchHistory.length == 0) this._historyWrapper.classList.remove("show");
        render(this._historyWrapper, this.renderHistory(false, false, false, this.showHistoryResult, false));
    }

    private getCustomColor(styleName) {
        let color;
        switch (true) {
            case /dark/i.test(styleName):
                color = "#8C8C8D";
                break;
            case /light/i.test(styleName):
                color = "#C6C6C6";
                break;
            case /satellite/i.test(styleName):
                color = "#F4F4F4";
                break;
            default:
                color = "#A8A8A8";
                break;
        }
        return color;
    }

    private getTrafficColor(severity, alternative) {
        let color;
        const opacity = this._selectedAlternative === alternative ? 1 : 0.4;
        switch (severity) {
            case 0:
                color = `rgb(18, 98, 144, ${opacity})`;
                break;
            case 1:
                color = `rgb(78, 199, 91, ${opacity})`;
                break;
            case 2:
                color = `rgb(237, 114, 6, ${opacity})`;
                break;
            case 3:
                color = `rgb(220, 29, 29, ${opacity})`;
                break;
            case 4:
                color = `rgb(142, 23, 23, ${opacity})`;
                break;
        }
        return color;
    }

    private createAlternativePopup(alternative) {
        const feature = this._geojson.features.find(feature => feature.properties.name == alternative);

        // Active popup
        const coordinates = (feature.geometry as LineString).coordinates;
        const lngLat = coordinates[ Math.floor(coordinates.length / 2) ] as LngLatLike;

        // Get result
        let result;
        if (alternative === "result") result = this.routingResults[ "result" ];
        else {
            const idx = alternative.split("-")[ 1 ];
            result = this.routingResults[ "alternatives" ][ idx ];
        }

        const popupContent = this.createHTMLPopup(result, true);
        if (this._activePopup) {
            this._activePopup.setLngLat(lngLat).setHTML(popupContent);
        } else {
            this._activePopup = new Popup({ anchor: "left", closeButton: false, closeOnClick: false, className: "alternative-popup" })
                .setLngLat(lngLat)
                .setHTML(popupContent)
                .addTo(this._map);
        }
    }

    private createHTMLPopup = (result, active) => {
        return `
        <div class="alternative-icon ${profiles[ this.activeProfile ].icon} ${active ? "active" : ""}"></div>
        <div>
            <div class="popup-label popup-time ${active ? "active" : ""}">${formatTime(result.total_time)}</div>
            <div class="popup-label popup-distance">${formatDistance(result.total_distance, this.units, false)}</div>
        </div>
    `;
    };
}
